
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import SvgIcon from "@/components/SvgIcon.vue"

@Component({
  components: {
    SvgIcon,
  },
})
export class NavDrawer extends Vue {
  @Prop({ default: "" }) title!: string
  @Prop({ default: false }) visible!: boolean

  close() {
    this.$emit("close")
  }
}
export default NavDrawer
