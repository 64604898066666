
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import {
  GET_PAGE_TITLE,
  GET_PAGE_SUBTITLE,
  GET_MP_ACTIVE,
} from "@/store/store-types"
import SvgIcon from "@/components/SvgIcon.vue"
import IconButton from "@/components/base/IconButton.vue"

@Component({
  components: {
    IconButton,
    SvgIcon,
  },
})
export default class NavBar extends Vue {
  @Prop({ default: false }) showNav!: boolean

  get pageTitle(): string {
    const { title, translate } = this.$store.getters[GET_PAGE_TITLE]
    if (translate) {
      return String(this.$t(title))
    }
    return title
  }

  get pageSubtitle(): string {
    const sub = this.$store.getters[GET_PAGE_SUBTITLE]
    if (sub && this.pageTitle) return ": " + sub
    if (sub && !this.pageTitle) return sub
    return sub
  }

  get showMp(): boolean {
    return this.$store.getters[GET_MP_ACTIVE]
  }
}
