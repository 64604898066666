
import { Component, Prop, Vue } from "vue-property-decorator"
import Modal from "@/components/Modal.vue"
import { Notification, notify } from "@/util/notifications"
import DiceRoller from "@/components/dice/DiceRoller.vue"
import { CharData } from "@/data/character/characterData"
import { SkillObj } from "@/skills"
import DiceRollerConfig from "@/components/dice/DiceRollerConfig.vue"
import FLButton from "@/components/base/FLButton.vue"
import DiceActions from "./DiceActions.vue"
import ExpandableSection from "@/components/ExpandableSection.vue"
import { IDiceConfig } from "@/dice/diceTypes"
import { FLNumberInput } from "@/components/base/FLNumberInput.vue"

export function defaultDice(): IDiceConfig {
  return {
    white: null,
    red: null,
    black: null,
    green: null,
    blue: null,
    orange: null,
  }
}

@Component({
  components: {
    DiceActions,
    DiceRoller,
    DiceRollerConfig,
    Modal,
    FLButton,
    FLNumberInput,
    ExpandableSection,
  },
})
export class DiceModal extends Vue {
  @Prop({ default: "" }) title!: string
  @Prop({ default: () => defaultDice() }) dice!: IDiceConfig
  @Prop({ default: null }) charData!: CharData
  @Prop({ default: false }) disablePush!: boolean
  // FIXME: Get charData via Vuex instead

  get showWillpower() {
    return this.charData && this.charData.willpower !== null
  }

  close(ev: any) {
    this.$emit("close")
  }
  onRoll() {
    this.rollCb()
  }
  onPush() {
    this.pushCb()
  }
  onReset() {
    this.resetCb()
  }

  onWillpower(value: number) {
    this.charData.willpower = value
  }

  canPush = this.disablePush
  canRoll = true

  pushCb = () => {}
  rollCb = () => {}
  resetCb = () => {}

  setCb(type: "push" | "roll" | "reset", fn: () => void) {
    switch (type) {
      case "push":
        this.pushCb = fn
        break
      case "roll":
        this.rollCb = fn
        break
      case "reset":
        this.resetCb = fn
        break
      default:
        /* eslint-disable-next-line no-console */
        console.error(`Got invalid cb type ${type}`)
        break
    }
  }

  updateCanPush(pushDisabled: boolean) {
    // FIXME: Move willpower & dwarf push logic here instead
    if (this.disablePush) {
      this.canPush = false
      return
    }
    this.canPush = !pushDisabled
  }
}

export default DiceModal
