
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import SvgIcon from "@/components/SvgIcon.vue"

const BASE_STORE_KEY = "__fl_expandable_section"

export enum EXPANDER_SLOT {
  header_center = "header",
  header_right = "icon-right",
}

@Component({
  components: {
    SvgIcon,
  },
})
export class ExpandableSection extends Vue {
  @Prop({ default: "" }) label!: string
  @Prop({ default: false }) defaultOpen!: boolean
  @Prop({ default: null }) saveStateId!: string | null
  @Prop({ required: false, default: null }) iconRight!: string
  @Prop({ default: undefined }) iconRightOK!: boolean | undefined
  @Prop({ default: true }) border!: boolean

  key = `${BASE_STORE_KEY}-${this.saveStateId}`
  isExpanded = this.load(this.key)
  focused = false
  SLOT = EXPANDER_SLOT

  load(key: string) {
    // don't load state if no unique id:
    if (!this.saveStateId) return this.defaultOpen

    const state = localStorage.getItem(key)
    // console.log("loading state", this.key, "state", state)
    if (state === null) return this.defaultOpen
    return state === "open" ? true : false
  }

  expandToggle(ev: any) {
    if (ev.type === "click") this.isExpanded = !this.isExpanded
    else if (this.focused && ev.code === "Enter") {
      this.isExpanded = !this.isExpanded
    }

    if (this.saveStateId) {
      // save toggled state if id is supplied
      localStorage.setItem(this.key, this.isExpanded ? "open" : "closed")
    }
  }

  focus() {
    // console.log("focus event", ev)
    // this.isExpanded = true
    this.focused = true
  }

  blur() {
    this.focused = false
  }

  getIconRightColor() {
    if (this.iconRightOK === undefined) return undefined
    return this.iconRightOK ? "icon-right-ok" : "icon-right-nok"
  }
}

export default ExpandableSection
