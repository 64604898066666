
import { Component, Prop, Vue } from "vue-property-decorator"
import IconButton from "@/components/base/IconButton.vue"

@Component({
  components: {
    IconButton,
  },
})
export default class Modal extends Vue {
  @Prop({ default: true }) maximized!: boolean
  @Prop({ default: "70%" }) width!: string
  @Prop({ default: "70%" }) height!: string
  @Prop({ default: true }) toggleBodyOverflow!: boolean
  @Prop({ default: "" }) title!: string

  close(param: string): void {
    document.onkeydown = null
    this.$emit("close", param)
  }

  bodyOverflow(show: boolean) {
    if (!this.toggleBodyOverflow) return
    if (!show) {
      document.querySelector("body")!.setAttribute("style", "overflow: hidden")
    } else {
      document.querySelector("body")!.removeAttribute("style")
    }
  }

  mounted() {
    document.onkeydown = (evt) => {
      evt = evt || window.event
      let isEscape = false
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc"
      }
      if (isEscape) {
        this.close("esc")
      }
    }
    this.bodyOverflow(false)
  }

  destroyed() {
    this.bodyOverflow(true)
  }
}
