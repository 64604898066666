
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import SvgIcon from "@/components/SvgIcon.vue"
import { DiceColor } from "@/components/dice/diceTypes"

@Component({
  components: {
    SvgIcon,
  },
})
export default class Dice extends Vue {
  @Prop({ required: true }) color!: DiceColor
  @Prop({ default: null }) value!: number

  whiteWash(diceName: string) {
    return this.color === "white" ? diceName + "-inverted" : diceName
  }

  get skullify() {
    return ["white", "black"].includes(this.color) ? "skulls-1" : "d6-1"
  }

  get swordify() {
    return ["white", "red", "black"].includes(this.color)
      ? "swords-1"
      : `swords-${this.value}`
  }
  get diceName() {
    if (!this.value) return this.baseIconName
    else if (this.value < 0) {
      return "skulls-1"
    } else if (this.value === 1) {
      return this.whiteWash(this.skullify)
    } else if (this.value < 6) {
      return this.whiteWash(`d6-${this.value}`)
    } else if (this.value === 6) {
      return this.whiteWash(this.swordify)
    }
    return `swords-${this.value}`
  }

  get baseIconName() {
    switch (this.color) {
      case "badred":
        return "skulls-1"
      case "red":
      case "black":
        return "d6-6"
      case "white":
        return "d6-6-inverted"
      case "green":
        return "d8"
      case "blue":
        return "d10"
      case "orange":
        return "d12"
      default:
        return "alert-error"
    }
  }

  get title() {
    switch (this.color) {
      case "white":
        return this.$t("attributes")
      case "red":
        return this.$t("skills")
      case "black":
        return this.$t("Gear")
      case "green":
        return this.$t("mighty")
      case "blue":
        return this.$t("epic")
      case "orange":
        return this.$t("legendary")
      default:
        return "UNKNOWN"
    }
  }

  get diceClass() {
    return `dice-icon dice-${this.color}`
  }
}
