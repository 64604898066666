
import { Component, Prop, Vue } from "vue-property-decorator"
import FLButton from "@/components/base/FLButton.vue"

@Component({
  components: {
    FLButton,
  },
})
export default class DiceActions extends Vue {
  @Prop({ required: true }) canPush!: boolean
  @Prop({ required: true }) canRoll!: boolean

  @Prop({ default: false }) showClose!: boolean
  @Prop({ default: true }) showReset!: boolean

  close(ev: any) {
    this.$emit("close")
  }
  resetDice() {
    this.$emit("reset")
  }
  pushRoll() {
    this.$emit("push")
  }
  rollDice() {
    this.$emit("roll")
  }
}
