
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import { DiceColor } from "@/components/dice/diceTypes"
import SvgIcon from "@/components/SvgIcon.vue"
import Dice from "./DiceType.vue"

@Component({
  components: {
    Dice,
    SvgIcon,
  },
})
export default class DiceResult extends Vue {
  @Prop({ required: true }) color!: DiceColor
  @Prop({ required: true }) rolls!: number[]
  @Prop({ default: false }) negativeBonus!: boolean
}
