<script>
import { langOptions, LOCALE_KEY } from "@/i18n"
import { SET_PAGE_TITLE, GET_PAGE_TITLE } from "@/store/store-types"
import { FLSelect } from "src/components/base/FLSelect.vue"

export default {
  components: {
    FLSelect,
  },
  name: "LocaleChanger",
  data() {
    return { langs: langOptions }
  },
  watch: {
    "$i18n.locale"() {
      localStorage.setItem(LOCALE_KEY, this.$i18n.locale)

      const pageTitle = this.$store.getters[GET_PAGE_TITLE]
      this.$store.commit(SET_PAGE_TITLE, pageTitle.title, pageTitle.translate)
    },
  },
}
</script>

<template>
  <div class="locale-changer">
    <FLSelect
      :label="$t('Language')"
      :options="langs"
      v-model="$i18n.locale"
      direction="column"
    />
  </div>
</template>

<style lang="less" scoped>
.locale-changer {
  display: flex;
  align-items: center;
  label {
    margin-right: 1rem;
  }
}
</style>
