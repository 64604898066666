import de from "@/locales/de"
import en from "@/locales/en"
import es from "@/locales/es"
import pt from "@/locales/pt"
import se from "@/locales/se"
import Vue from "vue"
import VueI18n, { LocaleMessageObject } from "vue-i18n"

Vue.use(VueI18n)

export type TSupportedLocales = "en" | "se" | "pt" | "de" | "es"

export const messages: {
  [key in TSupportedLocales]: LocaleMessageObject
} = {
  de,
  en,
  es,
  pt,
  se,
} as const

export function mapKeyToLanguage(key: TSupportedLocales) {
  switch (key) {
    case "de":
      return "German"
    case "en":
      return "English"
    case "es":
      return "Spanish"
    case "pt":
      return "Brazilian"
    case "se":
      return "Swedish"

    default:
      throw TypeError(`Invalid translations key: ${key}`)
  }
}

export const langOptions = (Object.keys(messages) as Array<
  keyof typeof messages
>)
  .map((id) => ({
    id,
    key: id,
    name: mapKeyToLanguage(id as TSupportedLocales),
  }))
  .sort((o, b) => (o.name >= b.name ? 1 : -1))

export const LOCALE_KEY = "__localeSetting"
const DEFAULT_LOCALE = "en"
const CURRENT_LOCALE = localStorage.getItem(LOCALE_KEY) || DEFAULT_LOCALE

// export default new VueI18n({
const i18n = new VueI18n({
  // TODO: VUE_APP_I18N_LOCALE is never used; reconfigure?
  locale: CURRENT_LOCALE || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  // silentTranslationWarn: true,
  messages,
})

export default i18n
