
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import { notify, notifier, Notification } from "@/util/notifications"
import { EventBus, BusEvent } from "@/util/eventBus"
import FLButton from "@/components/base/FLButton.vue"
import SvgIcon from "@/components/SvgIcon.vue"

@Component({
  components: {
    FLButton,
    SvgIcon,
  },
})
export default class Notify extends Vue {
  notifications: Notification[] = []

  mounted() {
    EventBus.$on(BusEvent.notification, (notifications: Notification[]) => {
      this.notifications = notifications
    })
  }

  notificationClass(notification: Notification) {
    return `notification-${notification.type}`
  }

  colorClass(notification: Notification) {
    return `color-${notification.type}`
  }

  close(id: string) {
    notifier.remove(id)
  }

  icon(notification: Notification) {
    return `alert-${notification.type}`
  }
  closeSymbol(notification: Notification) {
    if (notification.displayTime) {
      return "check"
    }
    return "close"
  }
}
