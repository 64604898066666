
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import FLNumberInput from "@/components/base/FLNumberInput.vue"
import Dice from "./DiceType.vue"
import { DiceColor } from "./diceTypes"

@Component({
  components: {
    Dice,
    FLNumberInput,
  },
})
export default class DiceInput extends Vue {
  @Prop({ required: true }) color!: DiceColor
  @Prop({ default: "" }) value!: string
  @Prop({ default: "0" }) min!: string
  @Prop({ default: "99" }) max!: string
  @Prop({ default: "" }) width!: string
  @Prop({ default: () => {} }) rollCb!: () => {}
  @Prop({ default: () => {} }) pushCb!: () => {}

  emit($value: any) {
    this.$emit("input", $value)
  }
}
