import {
  AgeTranslations,
  AttributeTranslations,
  GearDescriptionTranslations,
  KinTranslations,
  ProfessionTranslations,
  SkillTranslations,
  TalentTranslations,
} from "./localetypes"
import { ACTION_FAST, ACTION_SLOW } from "@/data/combat/typesCombat"

const age: AgeTranslations = {
  "age": "age",
  "adult": "adult",
  "old": "old",
  "young": "young",
  "": "?",
}

const professions: ProfessionTranslations = {
  sorcerer: "sorcerer",
  druid: "druid",
  fighter: "fighter",
  hunter: "hunter",
  minstrel: "minstrel",
  peddler: "peddler",
  rider: "rider",
  rogue: "rogue",
  champion: "champion",
}

const attributes: AttributeTranslations = {
  attributes: "attributes",
  agility: "agility",
  empathy: "empathy",
  strength: "strength",
  wits: "wits",
}

const kin: KinTranslations = {
  kin: "kin",
  dwarf: "dwarf",
  elf: "elf",
  goblin: "goblin",
  halfelf: "halfelf",
  halfling: "halfling",
  human: "human",
  orc: "orc",
  wolfkin: "wolfkin",

  alderlander: "alderlander",
  aslene: "aslene",
  ailander: "ailander",
}

const talents: TalentTranslations = {
  // Kin
  "True Grit": "True grit", // dwarf
  "Inner Peace": "Inner peace", // elf
  "Sneaky": "Sneaky", // goblin
  "Psychic Power": "Psychic power", // half-elf
  "Hard to Catch": "Hard to catch", // halfling
  "Adaptive": "Adaptive", // human
  "Unbreakable": "Unbreakable", // orc
  "Hunting Instincts": "Hunting instincts", // wolfkin

  "ambidextrous": "ambidextrous",
  "axe fighter": "axe fighter",
  "berserker": "berserker",
  "bowyer": "bowyer",
  "brawler": "brawler",
  "builder": "builder",
  "chef": "chef",
  "cold blooded": "cold blooded",
  "defender": "defender",
  "dragonslayer": "dragonslayer",
  "executioner": "executioner",
  "fast footwork": "fast footwork",
  "fast shooter": "fast shooter",
  "fearless": "fearless",
  "firm grip": "firm grip",
  "fisher": "fisher",
  "hammer fighter": "hammer fighter",
  "herbalist": "herbalist",
  "horseback fighter": "horseback fighter",
  "incorruptible": "incorruptible",
  "knife fighter": "knife fighter",
  "lightning fast": "lightning fast",
  "lockpicker": "lockpicker",
  "lucky": "lucky",
  "master of the hunt": "master of the hunt",
  "melee charge": "melee charge",
  "pack rat": "pack rat",
  "pain resistant": "pain resistant",
  "pathfinder": "pathfinder",
  "poisoner": "poisoner",
  "quartermaster": "quartermaster",
  "quickdraw": "quick draw",
  "sailor": "sailor",
  "sharpshooter": "sharpshooter",
  "sharp tongue": "sharp tongue",
  "shield fighter": "shield fighter",
  "sixth sense": "sixth sense",
  "smith": "smith",
  "spear fighter": "spear fighter",
  "steady feet": "steady feet",
  "sword fighter": "sword fighter",
  "tailor": "tailor",
  "tanner": "tanner",
  "threatening": "threatening",
  "throwing arm": "throwing arm",
  "wanderer": "wanderer",

  // Druid
  "Path of Healing": "Path of Healing",
  "Path of Shifting Shapes": "Path of Shifting Shapes",
  "Path of Sight": "Path of Sight",

  // Fighter
  "Path of the Blade": "Path of the Blade",
  "Path of the Enemy": "Path of the Enemy",
  "Path of the Shield": "Path of the Shield",

  // HUNTER
  "Path of the Arrow": "Path of the Arrow",
  "Path of the Beast": "Path of the Beast",
  "Path of the Forest": "Path of the Forest",

  // MINSTREL
  "Path of the Hymn": "Path of the Hymn",
  "Path of the Song": "Path of the Song",
  "Path of the Warcry": "Path of the Warcry",

  // Peddler
  "Path of Gold": "Path of Gold",
  "Path of Lies": "Path of Lies",
  "Path of Many Things": "Path of Many Things",

  // Rider
  "Path of the Companion": "Path of the Companion",
  "Path of the Knight": "Path of the Knight",
  "Path of the Plains": "Path of the Plains",

  // Rogue
  "Path of the Face": "Path of the Face",
  "Path of the Killer": "Path of the Killer",
  "Path of Poison": "Path of Poison",

  // Sorcerer
  "Path of Blood": "Path of Blood",
  "Path of Death": "Path of Death",
  "Path of Signs": "Path of Signs",
  "Path of Stone": "Path of Stone",

  // Expansion
  "harpooner": "harpooner",
  "mountaineer": "mountaineer",
  "warm blooded": "warm blooded",

  "Path of Protection": "Path of Protection",
  "Path of the Holy Vow": "Path of the Holy Vow",
  "Path of Fate": "Path of Fate",

  "Ice Affinity": "Ice Affinity",

  "Dream Magic": "Dream Magic",
  "Elemental Magic": "Elemental Magic",
  "Magma Song": "Magma Song",
  "Magnetism": "Magnetism",
  "Mentalism": "Mentalism",
}

const skills: SkillTranslations = {
  "skills": "skills",
  "animal handling": "animal handling",
  "crafting": "crafting",
  "endurance": "endurance",
  "healing": "healing",
  "insight": "insight",
  "lore": "lore",
  "manipulation": "manipulation",
  "marksmanship": "marksmanship",
  "melee": "melee",
  "might": "might",
  "move": "move",
  "performance": "performance",
  "scouting": "scouting",
  "sleight of hand": "sleight of hand",
  "stealth": "stealth",
  "survival": "survival",
}

const GearDescriptions: GearDescriptionTranslations = {
  gear_druid: `
    Staff or knife, one item of your choice from the list of trade goods
    (see page 182 in the Player's Handbook)
  `,
  gear_fighter: `
    A one-handed weapon of your choice, studded leather armor, one item of
    your choice from the list of trade goods
    (see page 182 in the Player's Handbook)
  `,
  gear_hunter: `
    Bow or sling, two items of your choice from the list of trade goods
    (see page 182 in the Player's Handbook)
  `,
  gear_minstrel: `
    Lute or flute, knife, one item of your choice from the list of trade goods
    (see page 182 in the Player's Handbook)
  `,
  gear_peddler: `
    Knife, three items of your choice from the list of trade goods
    (see page 182 in the Player's Handbook)
  `,
  gear_rider: `
    A riding horse (choose one from the Forbidden Lands custom card deck if you
    have it), spear or handaxe, short bow or sling, one item of your choice from
    the list of trade goods
    (see page 182 in the Player's Handbook)
  `,
  gear_rogue: `
    Dagger, two items of your choice from the list of trade goods
    (see page 182 in the Player's Handbook)
  `,
  gear_sorcerer: `
    Staff or knife, one item of your choice from the list of trade goods
    (see page 182 in the Player's Handbook)
  `,
  gear_champion: `
    One-handed weapon of choice, studded leather armor, closed helmet, small
    shield, item of choice from the list of trade goods.
    (see page 182 in the Player's Handbook)
    `,
}

const weapons = {
  "battleaxe": "battleaxe",
  "broadsword": "broadsword",
  "chainmail": "chainmail",
  "closed helmet": "closed helmet",
  "dagger": "dagger",
  "falchion": "falchion",
  "flail": "flail",
  "great helm": "great helm",
  "halberd": "halberd",
  "handaxe": "handaxe",
  "heavy crossbow": "heavy crossbow",
  "heavy warhammer": "heavy warhammer",
  "knife": "knife",
  "large shield": "large shield",
  "large wooden club": "large wooden club",
  "leather armor": "leather armor",
  "light crossbow": "light crossbow",
  "long spear": "long spear",
  "longbow": "longbow",
  "longsword": "longsword",
  "mace": "mace",
  "morningstar": "morningstar",
  "open helmet": "open helmet",
  "pike": "pike",
  "plate armor": "plate armor",
  "rock": "rock",
  "scimitar": "scimitar",
  "short bow": "short bow",
  "short spear": "short spear",
  "shortsword": "shortsword",
  "sling": "sling",
  "small shield": "small shield",
  "staff": "staff",
  "studded leather cap": "studded leather cap",
  "studded leather": "studded leather",
  "throwing axe": "throwing axe",
  "throwing knife": "throwing knife",
  "throwing spear": "throwing spear",
  "trident": "trident",
  "two handed axe": "two handed axe",
  "two handed sword": "two handed sword",
  "warhammer": "warhammer",
  "wooden club": "wooden club",
}

const combatActionsSlow: { [value in ACTION_SLOW]: string } = {
  "": "",
  "action-break_free": "break free",
  "action-cast": "cast spell",
  "action-charge": "charge",
  "action-crawl": "crawl",
  "action-flee": "flee",
  "action-grapple": "grapple",
  "action-persuade": "persuade",
  "action-shoot": "shoot",
  "action-slash": "slash",
  "action-stab": "stab",
  "action-taunt": "taunt",
  "action-unarmed_attack": "unarmed attack",
}

const combatActionsFast: { [value in ACTION_FAST]: string } = {
  "": "",
  "action-aim": "aim",
  "action-disarm": "disarm",
  "action-dodge": "dodge",
  "action-draw_weapon": "draw weapon",
  "action-fast_shot": "quick shot",
  "action-fast_stab": "knife stab",
  "action-feint": "feint",
  "action-get_up": "get up",
  "action-grapple_attack": "grapple attack",
  "action-headbutt": "headbutt",
  "action-parry": "parry",
  "action-power_word": "power word",
  "action-ready_weapon": "ready weapon",
  "action-retreat": "retreat",
  "action-run": "run",
  "action-shield_bash": "shield bash",
  "action-shove": "shove",
  "action-swing_weapon": "swing weapon",
  "action-use_item": "use item",
}

const keyedTranslations = {
  ATTRIB_REMAINING: "Current",
  CONFIRM_DELETE_CHAR: "Are you sure you wish to delete this character?",
  PHB_ref: "Page {page} in the Player's Handbook",
  GENERATE_DOWNLOAD_LINK: "Genererate download link",
}

const multiLine = {
  "Roll dice before session starts": "Roll dice before session starts",
  "XP_DESCR": `
    You receive XP after the end of each game session. Talk it through and let
    the whole group discuss what has happened. For each of the below questions
    that you can reply “yes” to, you get one XP:

    Did you participate in the game session? You get one XP just for being
    there.

    Did you travel through at least one hex on the game map that you had not
    visited before?

    Did you discover a new adventure site?

    Did you defeat one or more monsters?

    Did you find a treasure (1 gold or more)?

    Did you build a function in your stronghold?

    Did you activate your Pride?

    Did you suffer from your Dark Secret?

    Did you risk your life for another PC?

    Did you perform an extraordinary action of some kind?
  `,
  "REP_DESCR": `
    During the game, your Reputation score will increase. After a game
    session, if you have performed one or more great or terrible deeds of
    some kind, your Reputation score increases one point. What counts as a
    "great or terrible deed" is up to the GM, but here are some examples:

    A prominent NPC was killed, or saved from certain death.

    A feared monster was slain.

    A legendary treasure or artifact was found or stolen.

    The deed permanently altered the overall situation in an adventure site.

    The deed will have significant consequences for one of the major powers
    in the Forbidden Lands.

    You have built a certain function in your stronghold (see page 160).
  `,
  "CONFIRM_ACTIVATE_INVALID_CHAR": `
    Your character does not validate; are you sure you wish to activate it?
  `,
  "TEMPLATE_VALID_WARNING": `
    N.B: Creating a character from a template may result in a character that
    does not validate according to the character creation rules.

    However, even if the stats are invalid it is still possible to save and
    activate your character, should you wish to do so.
  `,
  "IMPORT_WARNING":
    "Warning: Importing a backup will replace all your current settings and characters",
}

const mixedCase = {
  "About FLC": "About FLC",
  "About": "About",
  "Activate": "Activate",
  "Active": "Active",
  "Add XP/Reputation": "Add XP/Reputation",
  "Add item": "Add item",
  "Add talent": "Add Talent",
  "Add": "Add",
  "Appearance": "Appearance",
  "Armor": "Armor",
  "Armslength": "Arm's length",
  "Arrows": "Arrows",
  "Artifact dice": "Artifact dice",
  "Back": "Back",
  "Base data": "Base data",
  "Basic dice": "Basic dice",
  "Bloodmarch": "Bloodmarch",
  "Body": "Body",
  "Cancel": "Cancel",
  "Carried by mount": "Carried by mount",
  "Characters": "Characters",
  "Choose": "Choose",
  "Class talent": "Class talent",
  "Class talents": "Class talents",
  "Close": "Close",
  "Clothing": "Clothing",
  "Coins": "Coins",
  "Cold": "Cold",
  "Comment": "Comment",
  "Conditions": "Conditions",
  "Consumables": "Consumables",
  "Create new character": "Create new character",
  "Create": "Create",
  "D": "D",
  "Damage": "Damage",
  "Dark secret": "Dark secret",
  "Deactivate": "Deactivate",
  "Dehydrated": "Dehydrated",
  "Delete": "Delete",
  "Details": "Details",
  "Dice roller": "Dice roller",
  "Dice": "Dice",
  "Download data": "Download data",
  "Drop": "Drop",
  "Edit": "Edit",
  "Encumbrance": "Encumbrance",
  "Enter external URL": "Enter external URL",
  "Experience gained": "Experience gained",
  "Experience": "Experience",
  "Face": "Face",
  "Features": "Features",
  "Female": "Female",
  "Filter": "Filter",
  "Food": "Food",
  "Forbidden Lands Companion": "Forbidden Lands Companion",
  "Gallery": "Gallery",
  "Gear": "Gear",
  "General talent": "General talent",
  "General talents": "General talents",
  "General": "General",
  "Get from URL": "Get from URL",
  "Get": "Get",
  "Heavy": "Heavy",
  "Helmet": "Helmet",
  "Import backup": "Import backup",
  "Inactive": "Inactive",
  "Inventory": "Inventory",
  "Kin talent": "Kin talent",
  "Kin": "Kin",
  "Language": "Language",
  "Light": "Light",
  "Long": "Long",
  "Male": "Male",
  "Miscellaneous": "Miscellaneous",
  "Mount": "Mount",
  "Mounted": "Mounted",
  "Move to backpack": "Move to backpack",
  "Move to mount": "Move to mount",
  "Multiplay": "Multiplay",
  "Multiplayer": "Multiplayer",
  "Name": "Name",
  "Near": "Near",
  "New": "New",
  "Next": "Next",
  "None selected": "None selected",
  "Notes": "Notes",
  "Options": "Options",
  "Other": "Other",
  "Personality": "Personality",
  "Please select the following in": "Please select the following in",
  "Portrait": "Portrait",
  "Post session": "Post session",
  "Pride": "Pride",
  "Profession": "Profession",
  "Protection": "Protection",
  "Push": "Push",
  "Range": "Range",
  "Relationships": "Relationships",
  "Remaining": "Remaining",
  "Remove": "Remove",
  "Reputation gained": "Reputation gained",
  "Reputation": "Reputation",
  "Reset": "Reset",
  "Roll dice": "Roll dice",
  "Sandbox": "Sandbox",
  "Save & Close": "Save & Close",
  "Save": "Save",
  "Select import file": "Select import file",
  "Select picture": "Select picture",
  "Select talent": "Select talent",
  "Shield": "Shield",
  "Short": "Short",
  "Spend XP": "Spend XP",
  "Starting gear": "Starting gear",
  "Starving": "Starving",
  "Talent": "Talent",
  "Template": "Template",
  "Tiny": "Tiny",
  "Tired": "Tired",
  "Torches": "Torches",
  "Total spent": "Total spent",
  "Type": "Type",
  "Use gear": "Use gear",
  "View": "View",
  "WP": "WP",
  "Water": "Water",
  "Weapon": "Weapon",
  "Weapons": "Weapons",
  "Weight": "Weight",
  "Willpower": "Willpower",
  "XP": "XP",
}

const lowercase = {
  "action": "action",
  "animal companion": "animal companion",
  "armor": "armor",
  "armslength": "arm's length",
  "artifact": "artifact",
  "attribute": "attribute",
  "axe": "axe",
  "backpack": "backpack",
  "blunt": "blunt",
  "bonus": "bonus",
  "bow": "bow",
  "category": "category",
  "character": "character",
  "childhood": "childhood",
  "combat": "combat",
  "combat-action-fast": "fast action",
  "combat-action-fast-performed": "fast action performed",
  "combat-action-slow": "slow action",
  "combat-action-slow-performed": "slow action performed",
  "confirm": "confirm",
  "create from template": "create from template",
  "crossbow": "crossbow",
  "description": "description",
  "dmg": "dmg",
  "edged": "edged",
  "epic": "epic",
  "female": "female",
  "fighting monster": "fighting monster",
  "formative event": "formative event",
  "helmets": "helmets",
  "hook": "hook",
  "humanoid": "humanoid",
  "initiative": "initiative",
  "item": "item",
  "knife": "knife",
  "legendary": "legendary",
  "long": "long",
  "male": "male",
  "melee weapons": "melee weapons",
  "mighty": "mighty",
  "monster": "monster",
  "movement rate": "movement rate",
  "name": "name",
  "near": "near",
  "next round": "next round",
  "no character": "no character",
  "no suitable weapon equipped": "no suitable weapon equipped",
  "none": "none",
  "opponent": "target",
  "options": "options",
  "or more": "or more",
  "other": "other",
  "parry": "parry",
  "parrying": "parrying",
  "pointed": "pointed",
  "polearm": "spear",
  "probability": "probability",
  "properties": "properties",
  "ranged weapons": "ranged weapons",
  "select character": "select character",
  "sex": "sex",
  "shields": "shields",
  "short": "short",
  "skill": "skill",
  "slow_loading": "slow loading",
  "sword": "sword",
  "talents": "talents",
  "thrown": "thrown",
  "unarmed": "unarmed",
}

const childhood = {
  "[childhood] child of the raven": "child of the raven",
  "[childhood] child of the raven story":
    "You grew up among the Raven Sisters, and learned to venerate the Raven god. You learned to fear the Rust Brothers, and to live off the land. It is in the wind that you feel the most free",
  "[childhood] druid's apprentice": "druid's apprentice",
  "[childhood] druid's apprentice story":
    "You were apprenticed to a druid, and learned everything you could of the holy mysteries, of rituals and sorcery. Your master is long dead, but you still remember his/her words.",
  "[childhood] guardian": "guardian",
  "[childhood] guardian story":
    "The Ailanders were always an oppressed and hunted people. You realized this early on, and were trained to protect your people from dangers with shield and sword in hand. You were soon hardened by battle, and wear the scars to prove it.",
  "[childhood] wanderer": "wanderer",
  "[childhood] wanderer story":
    "You had no fixed place in life, the road was your home, and the Forbidden Lands was your neighborhood. Already as a child, you had seen more of the land than most.",
  "[childhood] laborer": "laborer",
  "[childhood] laborer story":
    "You worked on a farm performing menial laborious chores. You know everything about grains, cattle, and crops. But deep inside you longed for something else.",
  "[childhood] herder": "herder",
  "[childhood] herder story":
    "You were taught as a child that livestock are the source of life. As an apprentice, you wandered the plains of the Forbidden Lands, guarding your herd. You spent your nights under starry skies, dreaming of adventure.",
  "[childhood] orphaned": "orphaned",
  "[childhood] orphaned story":
    "You have never met your mother or father, never had a family. Since you were little, you've had to care for yourself. It has hardened you and made you strong, but a feeling of rootlessness gnaws within you.",
  "[childhood] vagabond": "vagabond",
  "[childhood] vagabond story":
    "You and your family traveled from village to village, making a living doing simple jobs. You never had a real home, every day was a new adventure. You learned to live off the land, and the importance of staying together",
  "[childhood] squire": "squire",
  "[childhood] squire story":
    "You served a proud warrior. Carried her weapons and gear, took care of her horse, and learned everything you could. You dreamed of making a name for yourself in the Forbidden Lands, of one day being your own master",
  "[childhood] hard studies": "hard studies",
  "[childhood] hard studies story":
    "A learned man or woman took you under his/her wing and set you to studying the elder writings. The days were long, the learning hard, but in the end, you found that knowledge was the key to the world.",
  "[childhood] born in the saddle": "born in the saddle",
  "[childhood] born in the saddle story":
    "You have always felt more kinship with animals than humans, and it is only on the back of your horse, with the wind whipping your face, that you feel truly free. Together you are one entity, riding across everlasting expanses in the name of the god Horn.",
  "[childhood] dreamer": "dreamer",
  "[childhood] dreamer story":
    "You have always dreamed of gods, legends, and myhts. Ever since you were a tiny tot, you sat by the campfire listening to the clan's minstrel telling stories of the volcano god Horn, proud ancestors, and demonic invasions. It is in the dreams of your people, of heroes and legends, that you live to the fullest. Maybe one day you'll be able to create a legend of your own?",
  "[childhood] strongest of the clan": "strongest of the clan",
  "[childhood] strongest of the clan story":
    "Already as a child you learned that you were bigger and stronger than others. You had to work harder, be more responsible, fight the hardest. Some idolized you, others despised you.",
  "[childhood] taught by a wise women": "taught by a wise women",
  "[childhood] taught by a wise women story":
    "As a child you befriended a wise women of the clan. Soon you became her apprentice, and learned all about the history of your people, of ancient feats of heroism, and bloody wars.",
  "[childhood] hunter": "hunter",
  "[childhood] hunter story":
    "As a child you learned to live in tune with nature. The hunt is your task, and even if you learned to kill, you also learned to honor the value of the life of every animal.",
  "[childhood] child of the winds": "child of the winds",
  "[childhood] child of the winds story":
    "You were chosen as a Child of the Winds and set out on a long pilgrimage to the highlands and the mountains. All for the sake of your clan. You fulfilled your duty, but to this day you still unsure of where you feel at home.",
  "[childhood] smith's apprentice": "smith's apprentice",
  "[childhood] smith's apprentice story":
    "It has always in the sweltering heat of the forge that you felt most at home. You learned the mystery of steel during many long days in the glow of the fire.",
  "[childhood] mineborn": "mineborn",
  "[childhood] mineborn story":
    "Already as a child, you learned to navigate in the darkness, deep below the eart. It was in the presence of the rock and the cold that you felt most at home.",
  "[childhood] scout's apprentice": "scout's apprentice",
  "[childhood] scout's apprentice story":
    "You were always perceptive, always curious. It soon came to the attention of your clan members, and you were handpicked to learn the duties of a scout. Many long days where spent hiding, watching the dangers of the Forbidden Lands.",
  "[childhood] guardian-in-training": "guardian-in-training",
  "[childhood] guardian-in-training story":
    "You were chosen to be a guardian-in-training, serving as something similar to a squire to a proud and powerful dwarven warrior. It was arduous work, but also educational. Your master taught you to handle weapons, to temper your courage, and to stand proud - no matter the dangers you face.",
  "[childhood] carver": "carver",
  "[childhood] carver story":
    "Dwarves have always burrowed inwards and downwards. Created life out of rock. As a child you were fascinated by the art of shaping the mountain after your image. You became a carver, an engineer and builder, who worked as much with the mind as with brute strength.",
  "[childhood] loner": "loner",
  "[childhood] loner story":
    "You have never felt at home among your people, always keeping to yourself. You grew up in the shadow of your comrades. You saw things they didn´t see, did things they never imagined.",
  "[childhood] fighter": "fighter",
  "[childhood] fighter story":
    "You learned early on that you were talented with weapons. Soon, you were taken on by a warrior who taught you the mysteries of battle. You trained hard and long, and in time you became your master's equal.",
  "[childhood] child of the forest": "child of the forest",
  "[childhood] child of the forest story":
    "Ever since you were a child, you've been drawn to the fruits of the forests, to flowers, herbs, and lakes. You learned everything you could from the clan's herbalist, the scent of every flower, and which plants nurtured life - or ended it.",
  "[childhood] wolfling": "wolfling",
  "[childhood] wolfling story":
    "Your parents abandoned you when you were a child, but you found a new home with a wolf pack. You became one of the wolves, howled at the moon, loped across the plains, and ate raw flesh.",
  "[childhood] scrounger": "scrounger",
  "[childhood] scrounger story":
    "You learned as a child that the ownership of objects is nothing but a fleeting thought. You have always had a talent for acquiring things that aren't yours. It's like they just... appear.",
  "[childhood] child of the woods": "child of the woods",
  "[childhood] child of the woods story":
    "You grew up under the cover of the forest. You climbed the trees, and felt the wind whisper among the leaves. It is in the forest that you feel safe, and you know that its creatures will always protect you.",
  "[childhood] wildling": "wildling",
  "[childhood] wildling story":
    "Your clan was a bunch of mavericks who didn't care for old lineages and pacts. You grew up wild and free, no one has ever been able to tell you what to do.",
  "[childhood] storyteller": "storyteller",
  "[childhood] storyteller story":
    "As a wee goblin, you sat by the fire, eyes like saucers, and listened to the legends of your people being told by the clan elder. In your mind, you traveled through time and space, and relived the fantastic stories. You made sure that you learned all you could from the old goblin, and it didn't take long before you became one of the clan's storytellers.",
  "[childhood] on the run": "on the run",
  "[childhood] on the run story":
    "You have always been on the run from something. Never felt safe, and never had a lasting home. You have traveled from village to village in the Forbidden Lands, but have never been able to sleep with your back to the door.",
  "[childhood] artist": "artist",
  "[childhood] artist story":
    "Already as a child you discovered that you had a talent for artistic endeavors. It was in the arts and performance that you found peace, that was where you lived life to the fulest.",
  "[childhood] student": "student",
  "[childhood] student story":
    "You always knew that you were a little wiser than your friends. When a sage teacher took you on, you immersed yourself in the mysteries of the world. What you found in books was all that you had ever dreamed of and more.",
  "[childhood] acrobat": "acrobat",
  "[childhood] acrobat story":
    "As a child you were agile and nimble, climbed everywhere, and discovered every nook in your neighborhood of the village. You learned the art of performance, and the importance of the beauty in the Forbidden Lands.",
  "[childhood] ghost child": "ghost child",
  "[childhood] ghost child story":
    "You grew up without a real family or home. Instead, you sought out other youths who had run away or been cast out. You were called ghost children, and lived by stealing and performing odd jobs.",
  "[childhood] baker's apprentice": "baker's apprentice",
  "[childhood] baker's apprentice story":
    "You have always loved the scent of freshly baked bread. Since childhood,\n" +
    "    you have worked in the kitchen, baking bread that'd make anyone's mouth\n" +
    "    water.",
  "[childhood] craftsman": "craftsman",
  "[childhood] craftsman story":
    "You were always skilled with your fingers, and learned to master a craft early on. You expressed yourself through the objects you created. But they also made you long to see the works of the real masters.",
  "[childhood] raised in the kitchen": "raised in the kitchen",
  "[childhood] raised in the kitchen story":
    "You spent almost all of your childhood in the kitchen. Among the scents of soups, stews, and brews. Your learned the secrets of spices and the joy of a well-cooked meal.",
  "[childhood] bookworm": "bookworm",
  "[childhood] bookworm story":
    "When other children played in the forest, you preffered to bury your nose in an old book, reading of myths, dragons, and treasures.",
  "[childhood] minstrel": "minstrel",
  "[childhood] minstrel story":
    "It was in the stories of the suffering of your people that you found your true self. In your youth, you learned the songs of the betrayal of the elves, and the blood spilt in the wars. You will never forget.",
  "[childhood] worker": "worker",
  "[childhood] worker story":
    "Life was hard, even as a young orc. You have toiled for as long as you can remember. Worked hard to be able to live your life as you please.",
  "[childhood] brigand": "brigand",
  "[childhood] brigand story":
    "A strong orc is an orc that can care for himself. You realized early on that you must steal to survive. And why not? The orcs have been betrayed time and time again.",
  "[childhood] drifter": "drifter",
  "[childhood] drifter story":
    "You grew up on the road, in the forest, and on the plains. You have never been able to settle in one place. Your restless legs won't let you.",
  "[childhood] warrior": "warrior",
  "[childhood] warrior story":
    "You grew up on the battlefield. Had already slain your first enemy as a young orc. It was with weapon in hand that you felt respected for the first time.",
  "[childhood] howler": "howler",
  "[childhood] howler story":
    "You grew up among the howlers, wolfkin that moved between the clans, their own masters, free. You were trained in the telling of the tragic legends that have been told by generations of howlers before you.",
  "[childhood] hunter [wolfkin]": "hunter",
  "[childhood] hunter [wolfkin] story":
    "Already as a young pup you learned that blood is life. The older hunters in the clan taught you to respect the animals, even as you kill them. The hunt is the basis of life.",
  "[childhood] outcast": "outcast",
  "[childhood] outcast story":
    "When the other cubs ran with the pack, you were cast out. Alone, you learned to live off the forest, and that other kin could be more kind than your own. One day, you will find your way home.",
  "[childhood] tracker": "tracker",
  "[childhood] tracker story":
    "You learned early on that your sense of smell was better than your comrades, and you soon realized that you could find the most amazing things if you just followed your snout.",
  "[childhood] fighter [wolfkin]": "fighter",
  "[childhood] fighter [wolfkin] story":
    "You were the strongest in your litter, and completely dominated your siblings' games. As you grew bigger, the elders in the clan saw that you were a natural fighter, and trained you in the secrets of battle.",
}

const formative_events = {
  "[event] pilgrimage": "pilgrimage",
  "[event] pilgrimage story":
    "You performed a pilgrimage that lead you across the Forbidden Lands. You reached your destination after many hardships, but you will never forget your perilous journey.",
  "[event] pilgrimage items": "Receive a staff, a backpack, and a waterskin.",
  "[event] [event] hunted": "hunted",
  "[event] [event] hunted story":
    "Something happened that forced you to run for your life. Maybe you rubbed the wrong person the wrong way, maybe you were accused of something you hadn't done.",
  "[event] [event] hunted items":
    "Receive D3 doses sleeping poison and a dagger.",
  "[event] unexpected find": "unexpected find",
  "[event] unexpected find story":
    "You found a treasure marked with the sign of your god. You saw this as a sign that your mission was righteous, but others didn't see it the same way. Maybe there is still someone looking for the treasure?",
  "[event] unexpected find items":
    "Roll once on the table for Valuable finds in the lair, reroll if the result is below 32.",
  "[event] studied with a master": "studied with a master",
  "[event] studied with a master story":
    "A wise druid took you under his/her wing and taught you for a long period. It was an intense time, but also educational. Maybe your master is still alive somewhere, maybe he/she was killed by the Iron Guard.",
  "[event] studied with a master items": "Receive parchment, ink, and quill.",
  "[event] protected the forest": "protected the forest",
  "[event] protected the forest story":
    "You took it upon yourself to protect a forest against the evil and violence of strangers. You lived in the cover of proud trees, one with the beings of the forest.",
  "[event] protected the forest items":
    "Receive a short bow and a D12 Resource Die for arrows. ",
  "[event] lived as a hermit": "lived as a hermit",
  "[event] lived as a hermit story":
    "You sought the beauty of nature, away from the violent conflicts of the Forbidden Lands. When you finally returned to the land, you did so with newfound strength.",
  "[event] lived as a hermit items": "Receive a tent and a field kitchen.",
  "[event] captured": "captured",
  "[event] captured story":
    "You were shamefully captured by enemies. You were thrown in a dungeon to be forgotten and die. When the opportunity arose to escape, you took it.",
  "[event] captured items": "Receive studded leather armor.",
  "[event] bloody battle": "bloody battle",
  "[event] bloody battle story":
    "You took part in a terrible battle and were badly injured. When dawn broke, your friends had been defeated to the last man, and the enemy had left you to the ravens.",
  "[event] bloody battle items": "Receive a longsword.",
  "[event] a long march": "a long march",
  "[event] a long march story":
    "You signed on as a soldier and marched across the Forbidden Lands. You barely remember what you fought for, or who your enemies were. But you remember how the forces of nature took your friends one by one.",
  "[event] a long march items": "Receive a tent, a blanket, and a cauldron.",
  "[event] on horseback": "on horseback",
  "[event] on horseback story":
    "You learned to ride and signed on as a knight with a lord. You fought in skirmishes, patrolled and guarded the country from enemies.",
  "[event] on horseback items": "Receive a mount of your choice (not warhorse)",
  "[event] scout": "scout",
  "[event] scout story":
    "You signed on with a company of soldiers and were placed in the vanguard as a scout. Your task to discover the enemy before they discovered you, and you were good at it. Maybe something happened to your squad, maybe you weren't paying much attention just that once?",
  "[event] scout items":
    "Receive a light crossbow and a D12 Resource Die for arrows.",
  "[event] commander": "commander",
  "[event] commander story":
    "You commanded a squad of soldiers. Your comrades looked up to you and would die for you. But something happened, maybe you were betrayed, maybe defeated in an ambush. Your comrades fell or fled, but you survived.",
  "[event] commander items": "Receive chainmail armor and a closed helmet.",
  "[event] hunted a beast": "hunted a beast",
  "[event] hunted a beast story":
    "The hunt was everything. You tracked the beast over land, water, and mountain. At times, the roles were reversed, maybe at times you were the prey?",
  "[event] hunted a beast items": "Receive a short spear and a bear trap.",
  "[event] lived off the forest": "lived off the forest",
  "[event] lived off the forest story":
    "You dwelt in the forest and lived off it. During the days you hunted, and at night, you slept under the open sky. It was a simple but good life. It had to end one day.",
  "[event] lived off the forest items":
    "Receive a throwing spear and a dagger.",
  "[event] tamed a mount": "tamed a mount",
  "[event] tamed a mount story":
    "You caught a wild horse (or another suitable animal). It wasn't easy, but after a long time you tamed the creature and in time you became inseparable.",
  "[event] tamed a mount items":
    "Receive a mount of your choice. It cannot be a warhorse.",
  "[event] won an archery competition": "won an archery competition",
  "[event] won an archery competition story":
    "You realized early on that you had a natural talent for archery, and set out to become even better. When you visited a large village or castle, you participated in a competition and won.",
  "[event] won an archery competition items":
    "Receive a longbow and a D12 Resource Die for arrows.",
  "[event] guide": "guide",
  "[event] guide story":
    "You loaned your talents as a guide to those who paid the best. You led caravans of peddlers, troops of soldiers, and curious adventurers. You learned the art of traversing the Forbidden Lands and living off of it.",
  "[event] guide items": "Receive a tent, a cauldron, and a blanket.",
  "[event] hunted for a lord": "hunted for a lord",
  "[event] hunted for a lord story":
    "A nobleman noticed your skills and hired you as his forester. You lived a good life, half of the time in comfort in the lord's castle, the rest of the time hunting in the woods.",
  "[event] hunted for a lord items":
    "Receive a well-sewn cloak and a sleeping fur.",
  "[event] wrote a popular ballad": "wrote a popular ballad",
  "[event] wrote a popular ballad story":
    "A ballad or story that you wrote became immensely popular in the surrounding area. To this day, you hear local minstrels performing your piece.",
  "[event] wrote a popular ballad items":
    "Receive ink, a feather, and parchment.",
  "[event] worked as a minstrel": "worked as a minstrel",
  "[event] worked as a minstrel story":
    "You used your artistic talents to make a living as a minstre. Maybe you were of the traveling kind, maybe you worked at a more permanent location, like a tavern or a castle.",
  "[event] worked as a minstrel items": "Receive a lute.",
  "[event] inspirational journey": "inspirational journey",
  "[event] inspirational journey story":
    "You went on a journey across the Forbidden Lands, to learn of the kin, cultures, and legends. On your journey you encountered many adventures and gathered enough material to fill a smaller book of ballads.",
  "[event] inspirational journey items":
    "Receive a small tent, a blanket, and a cauldron.",
  "[event] sang for a lord": "sang for a lord",
  "[event] sang for a lord story":
    "Your talent drew the attention of a local lord and he offered you a place as an entertainer at his court. Your time in the castle was filled with wine, delicious food, and clinking silver. But also insidious intrigue, assassinations, and jealousy.",
  "[event] sang for a lord items": "Receive fine garments and a dagger",
  "[event] enlisted [minstrel]": "enlisted",
  "[event] enlisted [minstrel] story":
    "Life took an unexpected turn and you were enlisted in a company of soldiers. Life in the field was rough, but you made faithful friends and learned to live off the land.",
  "[event] enlisted [minstrel] items":
    "Receive leather armor and an open helmet.",
  "[event] dueled a rival": "dueled a rival",
  "[event] dueled a rival story":
    "A rival saw you as a thorn in his side. For a period, your fates were interwined in the most troublesome ways: love, money, and work. In the end, it was too much. You were challenged to a duel, and you were injured. Maybe your rival is still out somewhere.",
  "[event] dueled a rival items": "Receive a short sword.",
  "[event] caravan": "caravan",
  "[event] caravan story":
    "You were part of an arduous trade caravan that traveled through the Forbidden Lands. The caravan encountered many dangers, and not everyone made it to the final destination. Maybe someone was left behind on the way?",
  "[event] caravan items": "Receive a donkey",
  "[event] local business": "local business",
  "[event] local business story":
    "You saw a financial opportunity and you made the most of it. Maybe you imported fruit to a lord's castle, maybe you sold fabrics from Alderland. Business went swimmingly - for a while.",
  "[event] local business items": "Receive a cart",
  "[event] cheated": "cheated",
  "[event] cheated story":
    "A trusted companion deceived you and left you destitute. You learned and important lesson the hard way, and had to spend a long time rebuilding your life from the rubble. Maybe your old companion still alive?",
  "[event] cheated items": "Receive a small tent, a blanket and a cauldron.",
  "[event] side mission": "side mission",
  "[event] side mission story":
    "You teamed up with a group of adventurers and went on a great adventure in the Forbidden Lands. You had to face many dangers, and many perished on the journey, but you learned a lot about yourself on the way.",
  "[event] side mission items": "Receive a chainmail armor and a small shield.",
  "[event] smuggler": "smuggler",
  "[event] smuggler story":
    "You created a lucrative business by smuggling contraband between the settlements of the Forbidden Lands. Maybe you smuggled weapons, maybe forbidden drugs. You learned the importance of discretion and how to avoid attention.",
  "[event] smuggler items": "Receive a spyglass.",
  "[event] ran a shop": "ran a shop",
  "[event] ran a shop story":
    "You chose to settle in a village and run a shop for a period of your life. You could live off of it, but not very well. In the end, you realized that the quiet existance wasn't for you.",
  "[event] ran a shop items": "Receive a scale, ink, a quill, and parchment.",
  "[event] enlisted [rider]": "enlisted",
  "[event] enlisted [rider] story":
    "You joined a company of soldiers. Maybe you joined of your own free will, maybe you were conscripted. With your comrades you braved many hardships: battles, long marches, and harsh weather. In the end, something happened to make you leave the company.",
  "[event] enlisted [rider] items":
    "Receive studded leather armor and a large shield.",
  "[event] competed": "competed",
  "[event] competed story":
    "You earned a living as a competitive rider, and traveled from village to village, castle to castle and competed against other riders. As long as you were winning you had a good life, but when you started losing, it was time to move on.",
  "[event] competed items": "Receive a cloak.",
  "[event] traveled the land": "traveled the land",
  "[event] traveled the land story":
    "Your lust for adventure took over, and you went out for a long ride across the Forbidden Lands. You rode through miserable wastes, severe storms, and braves many dangers.",
  "[event] traveled the land items":
    "Receive a small tent, a cauldron, and a blanket.",
  "[event] caravan guard": "caravan guard",
  "[event] caravan guard story":
    "You took employment as a guard in a caravan, and escorted peddlers through dark forests and high mountains.",
  "[event] caravan guard items": "Receive a short spear",
  "[event] cattle herder": "cattle herder",
  "[event] cattle herder story":
    "You herded livestock to greener pastures in the Forbidden Lands. In the daytime, you guarded the herd from horseback. At night, you slept under the open sky.",
  "[event] cattle herder items": "Receive a lantern and a waterskin.",
  "[event] steppe rider": "steppe rider",
  "[event] steppe rider story":
    "You rode across the everlasting steppes of the Forbidden Lands and lived simply by hunting and selling meat and animals hides.",
  "[event] steppe rider items":
    "Receive a short bow and a D12 Resource Die for arrows",
  "[event] stole from the wrong person": "stole from the wrong person",
  "[event] stole from the wrong person story":
    "It was the perfect crime. For a while. You stole something valuable from the wrong person, at the wrong time. You were hunted and had to run for your life.",
  "[event] stole from the wrong person items": "Receive lockpicks.",
  "[event] imprisoned": "imprisoned",
  "[event] imprisoned story":
    "Guilty or not, you were caught and thrown in jail. You had to spend a long time in the darkness but never gave up. Finally, the chance to escape emerged, and you took it.",
  "[event] imprisoned items": "Receive a dagger.",
  "[event] successful heist": "successful heist",
  "[event] successful heist story":
    "You planned and successfully performed a heist. Maybe it was against a rich peddler, maybe against a local lord.",
  "[event] successful heist items":
    "Receive an item from Valuable finds in a lair. Reroll if the result is below 33.",
  "[event] joined a guild of thieves": "joined a guild of thieves",
  "[event] joined a guild of thieves story":
    "You sought out an association of thieves and became part of the community. Together, you planned heists, and carried out dangerous missions.",
  "[event] joined a guild of thieves items": "Receive leather armor.",
  "[event] enlisted [rogue]": "enlisted",
  "[event] enlisted [rogue] story":
    "You found yourself, probably against your will, enlisted in a company of soldiers. There you worked as a scout, and ranged far ahead of the others. Your time there was filled with misery and dangers, but also friendship.",
  "[event] enlisted [rogue] items":
    "Receive a short bow and a D12 Resource Die for arrows.",
  "[event] band of robbers": "band of robbers",
  "[event] band of robbers story":
    "You disappeared into the woods and joined a band of robbers that lived off peddlers and caravans who stumbled into their path. Life in the forest was carefree and good for as long as it lasted, but in the end, the robbers were ousted from the woods.",
  "[event] band of robbers items": "Receive a short sword.",
  "[event] failed experiment": "failed experiment",
  "[event] failed experiment story":
    "You performed a magical experiment that failed catastrophically. Maybe someone was seriously injured, maybe you were banished from your home.",
  "[event] failed experiment items":
    "Receive a D6 doses of hallucinogenic poison.",
  "[event] secluded studies": "secluded studies",
  "[event] secluded studies story":
    "You sought out a secluded location to immerse yourself in the study of magic. You lived off nature and your books and scrolls were your only companions.",
  "[event] secluded studies items": "Receive a small tent and a cauldron.",
  "[event] found strange artifact": "found strange artifact",
  "[event] found strange artifact story":
    "You found a strange artifact during your travels through the Forbidden Lands. You became obsessed with its history and the thought that it might hold magical powers.",
  "[event] found strange artifact items":
    "Roll once on the table for Valuable finds in the lair. Re-roll if the result is below 32.",
  "[event] court magician": "court magician",
  "[event] court magician story":
    "You took employment with a local lord and served as his arcane advisor. Maybe the lord had a hidden agenda, maybe you got into a conflict with higher ranking advisors. You learned much about people in positions of power.",
  "[event] court magician items":
    "Receive a bottle of expensive wine and a cloak.",
  "[event] entertainer": "entertainer",
  "[event] entertainer story":
    "You worked as an entertainer, using your knowledge of magic to perform illusioins and tricks. Maybe you worked at a run down inn, maybe you were employed at a lord's court.",
  "[event] entertainer items": "Receive fine garments and a crystal ball.",
  "[event] enlisted [sorcerer]": "enlisted",
  "[event] enlisted [sorcerer] story":
    "You were enlisted in a company of soldiers. You spent a long time in the field where you assisted with your magical expertise. You saw comrades killed, but you managed to make it out with just an ugly scar or two.",
  "[event] enlisted [sorcerer] items":
    "Receive studded leather armor and a closed helmet.",
}

export default {
  ...age,
  ...attributes,
  ...kin,
  ...professions,
  ...skills,
  ...talents,
  ...GearDescriptions,
  ...weapons,
  ...combatActionsFast,
  ...combatActionsSlow,
  ...keyedTranslations,
  ...mixedCase,
  ...lowercase,
  ...multiLine,
  ...childhood,
  ...formative_events,
}
