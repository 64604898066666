
import { Component, Prop, Vue } from "vue-property-decorator"

import FLButton from "@/components/base/FLButton.vue"
import SvgIcon from "@/components/SvgIcon.vue"

@Component({
  components: {
    FLButton,
    SvgIcon,
  },
})
export class IconButton extends Vue {
  @Prop({ required: false }) icon!: string
  @Prop({ default: "inherit" }) color!: "main" | "danger" | "inherit"
  @Prop({ default: "24px" }) width!: string
  @Prop({ default: "24px" }) height!: string
  @Prop({ default: null }) title!: string | null

  get slotPassed() {
    return !!this.$slots.default?.[0].text?.length
  }
  get cssVars() {
    return {
      width: this.width,
      height: this.height,
      marginRight: this.slotPassed ? "5px" : "unset",
    }
  }
}

export default IconButton
