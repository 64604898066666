
import { Component, Prop, Vue } from "vue-property-decorator"

import { NavContent } from "src/components/nav/NavContent.vue"
import { APP_VERSION } from "src/util/const"
import NavDrawer from "src/components/base/NavDrawer.vue"
import LocaleChanger from "@/components/LocaleChanger.vue"

@Component({
  components: {
    LocaleChanger,
    NavContent,
    NavDrawer,
  },
})
export class SideNav extends Vue {
  @Prop({ default: false }) visible!: boolean

  buildVersion = APP_VERSION
}

export default SideNav
