
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import {
  CharacterData,
  CharacterTalent,
  Conditions,
} from "@/data/character/characterData"

import { FLSelect, Option as FLOption } from "@/components/base/FLSelect.vue"

import {
  prob,
  probZeroSuccess,
  probBlackSkull,
  probWhiteSkull,
} from "@/dice/diceProbability"
import { IDiceConfig } from "@/dice/diceTypes"

import Dice from "./DiceType.vue"
import { maxPossible } from "@/dice/diceUtil"

const SWORDS = "⚔"

enum ProbType {
  "success",
  "whiteSkull",
  "blackSkull",
}

// const inPercent = (n: number) => Math.round(n * 100)
const inPercent = (n: number) => (n * 100).toFixed(0) + "%"

@Component({
  components: {
    Dice,
    FLSelect,
  },
})
export class DiceProbability extends Vue {
  @Prop({ required: true }) conf!: IDiceConfig
  @Prop({ default: () => ({}) }) pushedRoll!: IDiceConfig

  minSuccessValue = 1
  minWhiteSkull = 1
  minBlackSkull = 1
  sign = "≈"
  probType = ProbType

  get optionsSuccess() {
    const opts: FLOption[] = []
    opts.push({ id: String(0), name: `${0}` })
    for (let i = 1; i <= maxPossible(this.conf); i++) {
      opts.push({ id: String(i), name: `${i}` })
    }
    return opts
  }

  get optionsBlackSkull() {
    const opts: FLOption[] = []
    opts.push({ id: String(0), name: `${0}` })
    for (let i = 1; i <= maxPossible({ black: this.conf.black }); i++) {
      opts.push({ id: String(i), name: `${i}` })
    }
    return opts
  }

  get optionsWhiteSkull() {
    const opts: FLOption[] = []
    opts.push({ id: String(0), name: `${0}` })
    for (let i = 1; i <= maxPossible({ white: this.conf.white }); i++) {
      opts.push({ id: String(i), name: `${i}` })
    }
    return opts
  }

  options(probType: ProbType) {
    const optionsArray = (c: IDiceConfig) => {
      const opts: FLOption[] = []
      opts.push({ id: String(0), name: `${0}` })
      for (let i = 1; i <= maxPossible(c); i++) {
        opts.push({ id: String(i), name: `${i} ${this.$t("or more")}` })
      }
      return opts
    }

    switch (probType) {
      case ProbType.whiteSkull:
        return optionsArray({ white: this.conf.white })
      case ProbType.blackSkull:
        return optionsArray({ black: this.conf.black })
      default:
        return optionsArray(this.conf)
    }
  }

  getProb(probType: ProbType, minValue: number) {
    const mProb = (
      probFunc: typeof prob | typeof probBlackSkull | typeof probWhiteSkull,
      value: number
    ) => {
      if (value === 0) {
        return inPercent(1 - probFunc(this.conf, 1))
      }
      return inPercent(probFunc(this.conf, value))
    }
    switch (probType) {
      case ProbType.whiteSkull:
        return mProb(probWhiteSkull, minValue)
      case ProbType.blackSkull:
        return mProb(probBlackSkull, minValue)
      case ProbType.success:
        return mProb(prob, minValue)
      default:
        throw new Error(`Invalid probType ${probType}`)
    }
  }

  get successProb() {
    if (this.minSuccessValue === 0) {
      return inPercent(probZeroSuccess(this.conf))
    }
    return inPercent(prob(this.conf, this.minSuccessValue))
  }

  failBlackProb(min: number) {
    if (min === 0) {
      return inPercent(1 - probBlackSkull(this.conf, 1))
    }
    const probBlack = probBlackSkull(this.conf, min)
    return inPercent(probBlack)
  }

  get failWhiteProb() {
    if (this.minWhiteSkull === 0) {
      return inPercent(1 - probWhiteSkull(this.conf, 1))
    }
    const probWhite = probWhiteSkull(this.conf, this.minWhiteSkull)
    return inPercent(probWhite)
  }

  get containsBlack() {
    return this.conf.black
  }

  get containsWhite() {
    return this.conf.white
  }

  get containsDice() {
    return maxPossible(this.conf) > 0
  }
}

export default DiceProbability
