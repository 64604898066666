
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import { capitalize } from "@/util/util"

export type Option = {
  id: string | number
  name?: string
  extras?: string[]
}

export type Optgroup = {
  label: string
  options: Option[]
}

@Component({
  components: {},
})
export class FLSelect extends Vue {
  @Prop({ default: "" }) label!: string
  @Prop({ default: () => [] }) options!: Option[]
  @Prop({ default: () => [] }) optgroups!: Optgroup[]
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: "" }) initial!: string
  @Prop({ default: true }) initialDisabled!: boolean
  @Prop({ default: null }) value!: string | null
  @Prop({ default: false }) fullWidth!: boolean
  @Prop({ default: false }) inGrid!: boolean
  @Prop({ default: "row" }) direction!: "row" | "column"
  @Prop({ default: false }) translateOptions!: boolean

  capitalize = capitalize

  optionExtras(option: Option): string {
    if (!option.extras || !option.extras.length) return ""
    return " " + option.extras.join(" ")
  }

  renderOption(option: Option): string {
    if (option.name) {
      // If caller provides options with names, we assume they are pre-formatted
      return option.name + this.optionExtras(option)
    }
    if (this.translateOptions) {
      return (
        capitalize(String(this.$t(String(option.id)))) +
        this.optionExtras(option)
      )
    }
    return capitalize(String(option.id)) + this.optionExtras(option)
  }

  onInput(ev: any) {
    const newValue = ev.target.value
    this.$emit("input", newValue)
  }
}

export default FLSelect
