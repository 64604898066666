import {
  AgeTranslations,
  AttributeTranslations,
  GearDescriptionTranslations,
  KinTranslations,
  ProfessionTranslations,
  SkillTranslations,
  TalentTranslations,
} from "./localetypes"
import {
  ICombatAction,
  ACTION_FAST,
  ACTION_SLOW,
} from "@/data/combat/typesCombat"

const age: AgeTranslations = {
  "age": "edad",
  "adult": "adulta",
  "old": "vieja",
  "young": "joven",
  "": "?",
}

const professions: Omit<ProfessionTranslations, "champion"> = {
  sorcerer: "hechicero",
  druid: "druida",
  fighter: "guerrero",
  hunter: "cazador",
  minstrel: "juglar",
  peddler: "buhonero",
  rider: "jinete",
  rogue: "pícaro",
}

const attributes: AttributeTranslations = {
  attributes: "características",
  agility: "destreza",
  empathy: "empatía",
  strength: "fuerza",
  wits: "intelecto",
}

const kin: KinTranslations = {
  kin: "raza",
  dwarf: "enano",
  elf: "elfo",
  goblin: "goblin",
  halfelf: "semielfo",
  halfling: "mediano",
  human: "humano",
  orc: "orco",
  wolfkin: "lupino",

  alderlander: "aliseo",
  aslene: "asleno",
  ailander: "ayeo",
}

// Omitting untranslated talents
const talents: Omit<
  TalentTranslations,
  | "harpooner"
  | "mountaineer"
  | "warm blooded"
  | "Path of Protection"
  | "Path of the Holy Vow"
  | "Path of Fate"
  | "Ice Affinity"
  | "Elemental Magic"
  | "Magma Song"
  | "Mentalism"
  | "Dream Magic"
  | "Magnetism"
> = {
  // Kin
  "True Grit": "Testa Dura", // dwarf
  "Inner Peace": "Paz Interior", // elf
  "Sneaky": "Nocturno", // goblin
  "Psychic Power": "Poder Psíquico", // half-elf
  "Hard to Catch": "Escurridizo", // halfling
  "Adaptive": "Adaptable", // human
  "Unbreakable": "Imbatible", // orc
  "Hunting Instincts": "Instinto de caza", // wolfkin

  "ambidextrous": "ambidiestro",
  "axe fighter": "maestro del hacha",
  "berserker": "berserker",
  "bowyer": "arquero",
  "brawler": "puños desnudos",
  "builder": "constructor",
  "chef": "cocinero",
  "cold blooded": "sangre fría",
  "defender": "defensor",
  "dragonslayer": "matadragones",
  "executioner": "ejecutor",
  "fast footwork": "pies ligeros",
  "fast shooter": "tirador rápido",
  "fearless": "intrépido",
  "firm grip": "agarre firme",
  "fisher": "pescador",
  "hammer fighter": "maestro del martillo",
  "herbalist": "herborista",
  "horseback fighter": "guerrero montado",
  "incorruptible": "incorruptible",
  "knife fighter": "maestro del cuchillo",
  "lightning fast": "como el rayo",
  "lockpicker": "abrecandados",
  "lucky": "afortunado",
  "master of the hunt": "maestro de la caza",
  "melee charge": "acometida",
  "pack rat": "diógenes",
  "pain resistant": "resistente al dolor",
  "pathfinder": "buscasendas",
  "poisoner": "envenenador",
  "quartermaster": "intendente",
  "quickdraw": "desenvainado rápido",
  "sailor": "marinero",
  "sharpshooter": "tirador avezado",
  "sharp tongue": "lengua afilada",
  "shield fighter": "maestro del escudo",
  "sixth sense": "sexto sentido",
  "smith": "herrero",
  "spear fighter": "maestro de la lanza",
  "steady feet": "pies firmes",
  "sword fighter": "maestro de la espada",
  "tailor": "sastre",
  "tanner": "curtidor",
  "threatening": "amenazador",
  "throwing arm": "lanzador",
  "wanderer": "caminante",

  // Druid
  "Path of Healing": "Senda de la Curación",
  "Path of Shifting Shapes": "Senda del Cambio de Forma",
  "Path of Sight": "Senda de la Visión",

  // Fighter
  "Path of the Blade": "Senda de la Espada",
  "Path of the Enemy": "Senda del Enemigo",
  "Path of the Shield": "Senda del Escudo",

  // HUNTER
  "Path of the Arrow": "Senda de la Flecha",
  "Path of the Beast": "Senda de la Bestia",
  "Path of the Forest": "Senda del Bosque",

  // MINSTREL
  "Path of the Hymn": "Senda del Himno",
  "Path of the Song": "Senda de la Canción",
  "Path of the Warcry": "Senda del Grito de Guerra",

  // Peddler
  "Path of Gold": "Senda del Oro",
  "Path of Lies": "Senda de las Mentiras",
  "Path of Many Things": "Senda de las Muchas Cosas",

  // Rider
  "Path of the Companion": "Senda del Compañero",
  "Path of the Knight": "Senda del Caballero",
  "Path of the Plains": "Senda de las Llanuras",

  // Rogue
  "Path of the Face": "Senda del Rostro",
  "Path of the Killer": "Senda del Asesino",
  "Path of Poison": "Senda del Veneno",

  // Sorcerer
  "Path of Blood": "Senda de la Sangre",
  "Path of Death": "Senda de la Muerte",
  "Path of Signs": "Senda de los Signos",
  "Path of Stone": "Senda de la Piedra",
}

const skills: SkillTranslations = {
  "skills": "habilidades",
  "animal handling": "trato con animales",
  "crafting": "artesanía",
  "endurance": "resistencia",
  "healing": "curación",
  "insight": "perspicacia",
  "lore": "saber",
  "manipulation": "manipulación",
  "marksmanship": "puntería",
  "melee": "pelea",
  "might": "brío",
  "move": "atletismo",
  "performance": "interpretación",
  "scouting": "exploración",
  "sleight of hand": "juego de manos",
  "stealth": "sigilo",
  "survival": "supervivencia",
}

const GearDescriptions: Omit<GearDescriptionTranslations, "gear_champion"> = {
  gear_druid: `
    Bastón o cuchillo, un objeto a tu elección de la lista de Mercancias
  (ver página 182 del Manual del Jugador)
  `,
  gear_fighter: `
    Un arma a una mano de tu elección, armadura de cuero tachonado, un objeto de la lista de Mercancias
    (ver página 182 del Manual del Jugador)
  `,
  gear_hunter: `
    Arco u honda, dos objetos a tu elección de la lista de Mercancias
    (ver página 182 del Manual del Jugador)
  `,
  gear_minstrel: `
    Laúd o flauta, un objeto a tu elección de la lista de Mercancias
    (ver página 182 del Manual del Jugador)
  `,
  gear_peddler: `
    Cuchillo, tres objetos a tu elección de la lista de Mercancias
    (ver página 182 del Manual del Jugador)
  `,
  gear_rider: `
    Un caballo de monta (elige uno del mazo de cartas de Forbidden Lands si lo tienes)
    , lanza o hacha de mano, arco corto u honda, un objeto de la lista de Mercancias
    (ver página 182 del Manual del Jugador)
  `,
  gear_rogue: `
    Daga, dos objetos a tu elección de la lista de Mercancias
    (ver página 182 del Manual del Jugador)
  `,
  gear_sorcerer: `
    Bastón o cuchillo, un objeto de la lista de Mercancias
    (ver página 182 del Manual del Jugador)
  `,
}

const weapons = {
  "battleaxe": "hacha de combate",
  "broadsword": "espada ancha",
  "chainmail": "cota de mallas",
  "closed helmet": "casquete",
  "dagger": "daga",
  "falchion": "alfanje",
  "flail": "mangual",
  "great helm": "gran yelmo",
  "halberd": "alabarda",
  "handaxe": "hacha de mano",
  "heavy crossbow": "ballesta pesada",
  "heavy warhammer": "martillo de guerra pesado",
  "knife": "cuchillo",
  "large shield": "escudo grande",
  "large wooden club": "garrote",
  "leather armor": "armadura de cuero",
  "light crossbow": "ballesta ligera",
  "long spear": "lanza larga",
  "longbow": "arco largo",
  "longsword": "espada bastarda",
  "mace": "maza",
  "morningstar": "lucero del alba",
  "open helmet": "celada descubierta",
  "pike": "pica",
  "plate armor": "armadura de placas",
  "rock": "piedra",
  "scimitar": "cimitarra",
  "short bow": "arco corto",
  "short spear": "lanza corta",
  "shortsword": "espada corta",
  "sling": "honda",
  "small shield": "escudo pequeño",
  "staff": "bastón",
  "studded leather cap": "capacete de cuero tachonado",
  "studded leather": "cuero tachonado",
  "throwing axe": "hacha arrojadiza",
  "throwing knife": "cuchillo arrojadizo",
  "throwing spear": "javalina",
  "trident": "tridente",
  "two handed axe": "hacha a dos manos",
  "two handed sword": "espadón",
  "warhammer": "martillo de guerra",
  "wooden club": "clava",
}

const combatActionsSlow: { [value in ACTION_SLOW]: string } = {
  "": "",
  "action-break_free": "liberarse",
  "action-cast": "lanzar un conjuro",
  "action-charge": "cargar",
  "action-crawl": "arrastrarse",
  "action-flee": "huir",
  "action-grapple": "agarrar",
  "action-persuade": "persuadir",
  "action-shoot": "disparar",
  "action-slash": "estocada",
  "action-stab": "puñalada",
  "action-taunt": "provocar",
  "action-unarmed_attack": "puñetazo/patada/mordisco",
}

const combatActionsFast: { [value in ACTION_FAST]: string } = {
  "": "",
  "action-aim": "apuntarim",
  "action-disarm": "desarmar",
  "action-dodge": "esquivar",
  "action-draw_weapon": "desenvainar",
  "action-fast_shot": "quick shot",
  "action-fast_stab": "knife stab",
  "action-feint": "finta",
  "action-get_up": "levantarse",
  "action-grapple_attack": "ataque de agarre",
  "action-headbutt": "cabezazo",
  "action-parry": "parada",
  "action-power_word": "palabra de poder",
  "action-ready_weapon": "preparar arma",
  "action-retreat": "retirarse",
  "action-run": "correr",
  "action-shield_bash": "golpe de escudo",
  "action-shove": "empujar",
  "action-swing_weapon": "enarbolar arma",
  "action-use_item": "usar objeto",
}

const keyedTranslations = {
  ATTRIB_REMAINING: "Actual",
  CONFIRM_DELETE_CHAR: "¿Estas seguro que quieres borrar este personaje?",
  PHB_ref: "Pagina {page} en el Manual del Jugador",
  GENERATE_DOWNLOAD_LINK: "Generar enlace de descarga",
}

const multiLine = {
  "Roll dice before session starts":
    "Tira un dado antes de que comience la sesión",
  "XP_DESCR": `
    Recivid PX al final de cada sesión de juego. Comentad la parida permitiendo que todos los miembros del grupo hablen de lo que ha ocurrido.
    Por cada pregunta de las siguientes a las que puedas responder "sí", obtendrás 1 PX:

    ¿Has participado en la sesión de juego? Obtienes un PX solo por estar ahí.

    ¿Has viajado al menos por un hexágono del mapa de juego que no hubieras visitado antes?

    ¿Has descubierto un nuevo escenario de aventuras?
    
    ¿Has derrotado algún monstruo?
    
    ¿Has encontrado algún tesoro (1 moneda de oro o más)?

    ¿Has construido alguna función en vuestra fortaleza?

    ¿Has activado tu orgullo?
 
    ¿Has sufrido a causa de tu secreto oscuro?

    ¿Has arriesgado tu vida por otro PJ?

    ¿Has llevado a cabo algún tipo de acción extraordinaria?
  `,
  "REP_DESCR": `
    Durante la partida, tu puntuación de Reputación irá aumentando. Tras una sesión de juego en la 
    que hayas realizado alguna hazaña maravillosa o algún acto terrible de algún tipo, algo que se haya dado a
    conocer al mundo y de lo que se hablará en los años venideros, tu puntuación de Reputación aumenta un punto.
    Lo que cuenta como «hazaña maravillosa» o «acto terrible» queda a discreción de la directora, 
    pero aquí tienes algunos ejemplos:
    Matar a un PNJ relevante o salvarlo de una muerte segura.
    Matar a un monstruo temido.
    Encontrar o robar un tesoro o artefacto legendario.
    Una hazaña que altere permanentemente la situación general de un escenario de aventuras.
    Una hazaña que vaya a tener consecuencias importantes para una de las potencias principales de las Tierras Prohibidas.
    Haber construido cierta función en vuestra fortaleza (página 160)
  `,
  "CONFIRM_ACTIVATE_INVALID_CHAR": `
    Tu personaje no es valido; ¿Estas seguro de quere activarlo?
  `,
  "TEMPLATE_VALID_WARNING": `
    N.B: Crear un personaje desde una plantilla puede resultar en un personaje que sea valido según las reglas de creación de personaje.

    Sin embargo, incluso si los valores son invalidos grabar y activar a tu personaje si así lo deseas.
  `,
  "IMPORT_WARNING":
    "Aviso: Importar una copia de seguridad reemplazará todas tus preferencias y tus personajes actuales",
}

const mixedCase = {
  "About FLC": "Acerca de FLC",
  "About": "Acerca de",
  "Activate": "Activar",
  "Active": "Activado",
  "Add XP/Reputation": "Añadir PX/Reputación",
  "Add item": "Añadir objeto",
  "Add talent": "Añadir Talento",
  "Add": "Añadir",
  "Appearance": "Aspecto",
  "Armor": "Armadura",
  "Armslength": "Al alcance de la mano",
  "Arrows": "Flechas",
  "Artifact dice": "Dado de Artefacto",
  "Back": "Atras",
  "Base data": "Datos Base",
  "Basic dice": "Dados Base",
  "Body": "Cuerpo",
  "Cancel": "Cancelar",
  "Carried by mount": "Llevado por la montura",
  "Characters": "Personajes",
  "Choose": "Elije",
  "Class talent": "Talento de Clase",
  "Class talents": "Talentos de Clase",
  "Close": "Cerrar",
  "Clothing": "Ropas",
  "Coins": "Monedas",
  "Cold": "Aterido",
  "Comment": "Comentario",
  "Conditions": "Estados",
  "Consumables": "Consumibles",
  "Create new character": "Crear nuevo personaje",
  "Create": "Crear",
  "D": "D",
  "Damage": "Daño",
  "Dark secret": "Secreto Oscuro",
  "Deactivate": "Desactivar",
  "Dehydrated": "Sediento",
  "Delete": "Borrar",
  "Details": "Detalles",
  "Dice roller": "Lanzador de Dados",
  "Dice": "Dados",
  "Download data": "Descargar datos",
  "Drop": "Dejar caer",
  "Edit": "Editar",
  "Encumbrance": "Capacidad de Carga",
  "Enter external URL": "Introduzca URL externa",
  "Experience gained": "Experiencia obtenida",
  "Experience": "Experiencia",
  "Face": "Rostro",
  "Features": "Detalles",
  "Female": "Mujer",
  "Filter": "Filtro",
  "Food": "Comida",
  "Forbidden Lands Companion": "Forbidden Lands Companion",
  "Gallery": "Galeria",
  "Gear": "Equipo",
  "General talent": "Talento General",
  "General talents": "Talentos Generales",
  "General": "General",
  "Get from URL": "Obtener de URL",
  "Get": "Obtener",
  "Heavy": "Pesado",
  "Helmet": "Casco",
  "Import backup": "Importar copia de seguridad",
  "Inactive": "Inactivo",
  "Inventory": "Inventario",
  "Kin talent": "Talento de raza",
  "Kin": "Raza",
  "Language": "Lenguaje",
  "Light": "Ligero",
  "Long": "Largo",
  "Male": "Hombre",
  "Miscellaneous": "Miscelanea",
  "Mount": "Montura",
  "Mounted": "Montado",
  "Move to backpack": "Mover a la mochila",
  "Move to mount": "Move a la montura",
  "Multiplay": "Multi-jugadores",
  "Multiplayer": "Multi-jugador",
  "Name": "Nombre",
  "Near": "Cerca",
  "New": "Nuevo",
  "Next": "Siguiente",
  "None selected": "Ninguno seleccionado",
  "Notes": "Notas",
  "Options": "Opciones",
  "Other": "Otro",
  "Personality": "Personalidad",
  "Please select the following in": "Por favor seleccione entre",
  "Portrait": "Retrato",
  "Post session": "Tras la sesión",
  "Pride": "Orgullo",
  "Profession": "Profesión",
  "Protection": "Protección",
  "Push": "Forzar",
  "Range": "Distancia",
  "Relationships": "Relacciones",
  "Remaining": "Restante",
  "Remove": "Eliminar",
  "Reputation gained": "Reputación obtenida",
  "Reputation": "Reputación",
  "Reset": "Reiniciar",
  "Roll dice": "Lanzar dados",
  "Sandbox": "Sandbox",
  "Save & Close": "Grabar y Cerrar",
  "Save": "Grabar",
  "Select import file": "Seleccionar fichero a importar",
  "Select picture": "Seleccionar imagen",
  "Select talent": "Seleccionar talento",
  "Shield": "Escudo",
  "Short": "Corta",
  "Spend XP": "Gastar PX",
  "Starting gear": "Equipo de Inicio",
  "Starving": "Hambriento",
  "Talent": "Talento",
  "Template": "Plantilla",
  "Tiny": "Diminuto",
  "Tired": "Somnoliento",
  "Torches": "Antorchas",
  "Total spent": "Total gastado",
  "Type": "Tipo",
  "Use gear": "Usar equipo",
  "View": "Ver",
  "WP": "PV",
  "Water": "Agua",
  "Weapon": "Arma",
  "Weapons": "Armas",
  "Weight": "Peso",
  "Willpower": "Fuerza de Voluntad",
  "XP": "PX",
  "champion": "Campeón",
  "harpooner": "Arponero",
  "mountaineer": "Montañero",
  "warm blooded": "Sangre Caliente",
  "Path of Protection": "Senda de Protección",
  "Path of the Holy Vow": "Senda del Voto Sagrado",
  "Path of Fate": "Senda del Destino",
  "Ice Affinity": "Afinidad con el Hielo",
  "Dream Magic": "Magia de Sueños",
  "Elemental Magic": "Magia Elemental",
  "Magma Song": "Canción de Magma",
  "Magnetism": "Magnetismo",
  "Mentalism": "Mentalismo",
  "gear_champion":
    "Un arma a una mano a tu elección, armadura de cuero tachonado, celada descubierta, escudo pequeño, un objeto a tu elección de la lista de Mercancias (ver página 182 del Manual del Jugador))",
  "Bloodmarch": "Marcha de Sangre",
  "[childhood] child of the raven": "hija del cuervo",
  "[childhood] child of the raven story":
    "Creciste entre las Hermanas del Cuervo y te enseñaron a venerar al dios Cuervo. Aprendiste a temer a los Hermanos Herrumbrosos, y a vivir de la tierra. Es al aire libre donde te sientes mejor.",
  "[childhood] druid's apprentice": "aprendiza de druida",
  "[childhood] druid's apprentice story":
    "Estuviste bajo la tutela de un druida y aprendiste todo lo que pudiste de los misterios sagrados, de rituales y hechicerías. Tu maestro hace tiempo que murió, pero aún recuerdas sus palabras",
  "[childhood] guardian": "guardiana",
  "[childhood] guardian story":
    "Los ayeos siempre fueron un pueblo perseguido y oprimido. Lo aprendiste enseguida, y fuiste entrenada para proteger a tu gente con una espada y un escudo. Pronto te endureciste en batalla, y llevas las cicatrices que lo demuestran.",
  "[childhood] wanderer": "nómada",
  "[childhood] wanderer story":
    "No vivías en ningún lugar, el camino era tu hogar, y las Tierras Prohibidas tu vecindad. Ya de niña viste más tierras que la mayoría.",
  "[childhood] laborer": "granjera",
  "[childhood] laborer story":
    "Trabajaste en una granja realizando quehaceres domésticos y laboriosos. Lo sabes todo sobre cereales, ganado y cosechas. Pero en el fondo deseabas algo más.",
  "[childhood] herder": "pastora",
  "[childhood] herder story":
    "De niña te enseñaron que el ganado es la fuente de la vida. Como aprendiza recorriste las llanuras de las Tierras Prohibidas mientras cuidabas de tu rebaño. Pasaste las noches bajo las estrellas, soñando con vivir aventuras",
  "[childhood] orphaned": "huérfana",
  "[childhood] orphaned story":
    "No conoces a tu padre ni a tu madre y nunca has tenido familia. Has cuidado de ti misma desde pequeña. Fue duro y te ha hecho fuerte, pero hay un sentimiento de desarraigo que te corroe.",
  "[childhood] vagabond": "vagabunda",
  "[childhood] vagabond story":
    "Tu familia y tú viajasteis de pueblo a pueblo para ganaros la vida con pequeños trabajos. Nunca tuvisteis un hogar, cada día era una nueva aventura. Aprendiste a vivir de la tierra y que era muy importante mantenerse unidos.",
  "[childhood] squire": "escudera",
  "[childhood] squire story":
    "Serviste a las órdenes de una orgullosa guerrera. Llevabas sus armas y equipo, cuidabas de su caballo y aprendiste todo lo que pudiste. Soñabas con hacerte un nombre en las Tierras Prohibidas y que un día serías tu propia señora.",
  "[childhood] hard studies": "pupíla",
  "[childhood] hard studies story":
    "Un hombre o mujer docto te tomó bajo su tutela y te puso a estudiar los escritos antiguos. Los días eran largos, pero aprendiste mucho y, al final, te diste cuenta de que el conocimiento era la clave de todo.",
  "[childhood] born in the saddle": "criada en la silla",
  "[childhood] born in the saddle story":
    "Siempre te has sentido más conectada con los animales que con los humanos, y solo te sientes verdaderamente libre en la grupa de tu caballo y el viento en tu cara. Juntos sois uno mientras cabalgáis por las interminables extensiones en nombre del dios Asta.",
  "[childhood] dreamer": "soñadora",
  "[childhood] dreamer story":
    "Siempre has soñado con dioses, leyendas y mitos. De pequeña te sentabas en el fuego para escuchar al juglar del clan cantando historias del dios volcán Asta, vuestros orgullosos ancestros y las invasiones de demonios. Es en los sueños de tu pueblo, de héroes y leyendas, donde te sientes totalmente viva. ¿Serás una leyenda algún día?",
  "[childhood] strongest of the clan": "la más fuerte del clan",
  "[childhood] strongest of the clan story":
    "Ya de pequeña aprendiste que eras más grande y fuerte que los demás. Tuviste que trabajar más duro, ser más responsable, la más difícil de vencer en combate. Algunos te idolatraban, otros te despreciaban.",
  "[childhood] taught by a wise women": "discípula de una sabia",
  "[childhood] taught by a wise women story":
    "De niña te hiciste amiga de una sabia del clan. Pronto fuiste su aprendiza y aprendiste todo sobre la historia de tu gente, las antiguas hazañas heroicas y las guerras sangrientas..",
  "[childhood] hunter": "cazadora",
  "[childhood] hunter story":
    "De niña aprendiste a vivir en sintonía con la naturaleza. Tu tarea es la caza, y aunque aprendiste a matar, también aprendiste a honrar la importancia de la vida de cada animal..",
  "[childhood] child of the winds": "hija de los vientos",
  "[childhood] child of the winds story":
    "Fuiste elegida hija de los vientos y emprendiste un largo peregrinaje a las tierras altas y las montañas, todo por el bien de tu clan. Cumpliste tu deber, pero ahora no estás segura de dónde sentirte como en casa.",
  "[childhood] smith's apprentice": "aprendiza de herrera",
  "[childhood] smith's apprentice story":
    "Siempre te has sentido cómoda en el sofocante calor de la forja. Aprendiste el secreto del acero en los largos días a la luz del brillo del fuego.",
  "[childhood] mineborn": "nacida en la mina",
  "[childhood] mineborn story":
    "Ya de niña aprendiste a orientarte a oscuras en las profundidades bajo tierra. Te sientes como en casa cuando estás rodeada de piedra y frío.",
  "[childhood] scout's apprentice": "aprendiza del explorador",
  "[childhood] scout's apprentice story":
    "Siempre has sido muy perspicaz y curiosa. Pronto llamaste la atención de tu clan y te eligieron para que aprendieras el oficio de explorador. Pasaste muchos días escondida observando los peligros de las Tierras Prohibida.",
  "[childhood] guardian-in-training": "guardiana novel",
  "[childhood] guardian-in-training story":
    "Te eligieron para ser una guardiana novel, una especie de escudera al servicio de un orgulloso y poderoso guerrero enano. Fue un trabajo duro, pero también aprendiste mucho. Tu señor te enseñó a manejar las armas, a templar tu coraje y a mantenerte firme ante cualquier peligro.",
  "[childhood] carver": "tallista",
  "[childhood] carver story":
    "Los enanos siempre han excavado muy abajo y profundo. Crearon vida a partir de la roca. De niña te fascinaba el arte de tallar la montaña según tus ideas. Te hiciste tallista, ingeniera y constructora, y trabajaste tanto con la mente como con la pura fuerza bruta.",
  "[childhood] loner": "solitaria",
  "[childhood] loner story":
    "Nunca te has sentido a gusto entre tu gente, siempre te has buscado la vida tú sola. Creciste a la sombra de los tuyos. Viste cosas que ellos no vieron, hiciste cosas que nunca imaginaron.",
  "[childhood] fighter": "luchadora",
  "[childhood] fighter story":
    "Aprendiste pronto que tenías talento para las armas. De muy joven te tomaron como aprendiza de un guerrero que te enseñó los secretos del combate. Entrenaste mucho, y con el tiempo igualaste a tu maestro",
  "[childhood] child of the forest": "hija del bosque",
  "[childhood] child of the forest story":
    "Desde que eras niña te has sentido atraída por los frutos de los bosques, las flores, hierbas y lagos. Aprendiste todo lo que pudiste del herborista del clan, la esencia de cada flor y qué plantas nutrían la vida… o terminaban con ella.",
  "[childhood] wolfling": "criada con lobos",
  "[childhood] wolfling story":
    "Tus padres te abandonaron cuando eras una niña, pero encontraste un nuevo hogar en una manada de lobos. Fuiste una de ellos, aullaste a la luna, corriste dando zancadas por las llanuras y comiste carne cruda.",
  "[childhood] scrounger": "buscavidas",
  "[childhood] scrounger story":
    "De niña aprendiste que la propiedad no era sino una idea efímera. Siempre has tenido talento para adquirir cosas que no eran tuyas. Era como si… aparecieran de repente",
  "[childhood] child of the woods": "hija del bosque",
  "[childhood] child of the woods story":
    "Creciste al abrigo del bosque. Trepaste a los árboles, sentiste el viento susurrar entre las hojas. En el bosque te sientes segura, y sabes que sus criaturas siempre te protegerán..",
  "[childhood] wildling": "salvaje",
  "[childhood] wildling story":
    "Tu clan era un puñado de disidentes a los que no les importaban las tradiciones y los pactos. Creciste salvaje y libre, nadie pudo decirte nunca qué tenías que hacer.",
  "[childhood] storyteller": "cuentacuentos",
  "[childhood] storyteller story":
    "Cuando eras una pequeña goblin te sentabas al calor del fuego, con los ojos como platos, para escuchar las leyendas de tu pueblo que contaba el anciano del clan. Viajaste con tu imaginación a través del espacio y el tiempo para revivir esas fantásticas historias. Te aseguraste de aprender todo lo que pudiste de ese viejo goblin, y al poco tiempo te convertiste en una de las cuentacuentos del clan.",
  "[childhood] on the run": "siempre huyendo",
  "[childhood] on the run story":
    "Siempre has estado huyendo de algo. Nunca te has sentido a salvo y nunca has tenido un hogar. Has estado viajando de ciudad en ciudad por las Tierras Prohibidas, pero nunca has podido dormir de espaldas a la puerta..",
  "[childhood] artist": "artista",
  "[childhood] artist story":
    "Ya de pequeña descubriste que tenías talento para las empresas artísticas. Es en las artes y las letras donde encuentras la paz, donde te sientes plena.",
  "[childhood] student": "estudiante",
  "[childhood] student story":
    "Siempre supiste que tus amigos te veían como una pequeña sabia. Te sumergiste en los misterios del mundo cuando un docto maestro te tomó como aprendiza. Encontraste en los libros lo que siempre habías soñado, y mucho más.",
  "[childhood] acrobat": "acróbata",
  "[childhood] acrobat story":
    "De niña eras ágil y veloz, trepabas a todas partes y descubrías todos los recovecos de tu pueblo. Aprendiste el arte de la interpretación y la importancia de lo hermoso en las Tierras Prohibidas.",
  "[childhood] ghost child": "niña perdida",
  "[childhood] ghost child story":
    "Creciste sin familia ni hogar, así que siempre buscabas a otros jóvenes que hubieran huido de casa o que los hubieran echado. Te llamaban niña perdida, y siempre viviste de robar y de hacer trabajitos de vez en cuando.",
  "[childhood] baker's apprentice": "aprendiza de panadero",
  "[childhood] baker's apprentice story":
    "Siempre te encantó el olor del pan recién hecho. Trabajaste en la cocina desde pequeña, haciendo el pan que después haría la boca agua a los demás.",
  "[childhood] craftsman": "artesana",
  "[childhood] craftsman story":
    "Siempre fuiste habilidosa con los dedos y pronto te convertiste en una experta artesana. Te expresas a través de los objetos que creas, pero también te hacen desear ver las obras de los maestros de verdad.",
  "[childhood] raised in the kitchen": "criada en la cocina",
  "[childhood] raised in the kitchen story":
    "Pasaste casi toda tu infancia en la cocina, entre los olores de las sopas, estofados e infusiones. Aprendiste los secretos de las especias y el placer de una comida bien hecha.",
  "[childhood] bookworm": "ratona de biblioteca",
  "[childhood] bookworm story":
    "Los demás niños jugaban en el bosque, pero tú preferías enterrar tu nariz en los libros viejos y leer sobre mitos, dragones y tesoros.",
  "[childhood] minstrel": "trovadora",
  "[childhood] minstrel story":
    "Era en las historias de sufrimiento de tu pueblo donde te encontrabas a ti misma. De pequeña aprendiste las canciones de la traición de los elfos y de la sangre derramada en las guerras. Nunca lo olvidarás",
  "[childhood] worker": "trabajadora",
  "[childhood] worker story":
    "La vida era duda, incluso desde que eras joven. Has trabajado muy duro desde que tienes memoria, te has esforzado para poder vivir tu vida como has querido.",
  "[childhood] brigand": "forajida",
  "[childhood] brigand story":
    "Una orco fuerte es una orco que puede cuidar de sí misma. Aprendiste pronto que tenías que robar para sobrevivir. ¿Y por qué no? A los orcos los han traicionado una y otra vez.",
  "[childhood] drifter": "vagabunda",
  "[childhood] drifter story":
    "Creciste en el camino, en el bosque, en las llanuras. Nunca has podido asentarte en un lugar. Tus incansables piernas no te dejarían.",
  "[childhood] warrior": "guerrera",
  "[childhood] warrior story":
    "Creciste en el campo de batalla. Mataste a tu primer enemigo siendo muy joven. Fue con un arma en las manos cuando te sentiste respetada por primera vez.",
  "[childhood] howler": "aulladora",
  "[childhood] howler story":
    "Creciste entre los aulladores, lupinos que se movían entre clanes, libres, siendo sus propios amos. Aprendiste todo lo que sabes en los relatos de las trágicas leyendas que se han contado durante generaciones por los aulladores que te precedieron.",
  "[childhood] hunter [wolfkin]": "cazadora",
  "[childhood] hunter [wolfkin] story":
    "Ya cuando eras una cachorra aprendiste que la sangre es la vida. Los cazadores ancianos del clan te enseñaron a respetar a los animales, incluso cuando los matabas. La caza es la base de la vida.",
  "[childhood] outcast": "paria",
  "[childhood] outcast story":
    "Cuando los demás cachorros corrieron con la manada, te dejaron a un lado. Aprendiste sola a vivir en el bosque y que otras razas podían ser más amables que la tuya propia. Algún día regresarás a casa.",
  "[childhood] tracker": "rastreadora",
  "[childhood] tracker story":
    "Aprendiste pronto que tu sentido del olfato era mejor que el de tus hermanos, y te diste cuenta de que podías encontrar las cosas más maravillosas si seguías a tu hocico.",
  "[childhood] fighter [wolfkin]": "luchadora",
  "[childhood] fighter [wolfkin] story":
    "Eras la más fuerte de la camada, y siempre ganabas en los juegos de tus hermanos. Al crecer, los ancianos del clan te vieron como una luchadora natural y te entrenaron en los secretos de la batalla.",
  "[event] pilgrimage": "peregrinaje",
  "[event] pilgrimage story":
    "Llevaste a cabo una peregrinación por las Tierras Salvajes. Llegaste a tu destino después de muchas adversidades y nunca olvidarás el peligroso viaje.",
  "[event] pilgrimage items":
    "Obtienes un bastón, una mochila y un odre de agua.",
  "[event] [event] hunted": "perseguida",
  "[event] [event] hunted story":
    "Algo te pasó que te obligó a correr por tu vida. Puede que te tropezaras con la persona equivocada o que te acusaran de algo que no hiciste",
  "[event] [event] hunted items":
    "Obtienes 1D3 dosis de veneno narcótico y una daga.",
  "[event] unexpected find": "hallazgo inesperado",
  "[event] unexpected find story":
    "Encontraste un tesoro marcado con el signo de tu dios. Viste esto como una señal de que tu misión era recta, pero los demás no pensaron lo mismo. ¿Puede que aún haya alguien buscando el tesoro?",
  "[event] unexpected find items":
    "Tira en la tabla de «Hallazgos valiosos en guarida»; vuelve a tirar si el resultado es menor de 32.",
  "[event] studied with a master": "aprendiza de maestro",
  "[event] studied with a master story":
    "Un sabio druida te tomó bajo su tutela y te enseñó durante mucho tiempo. Fue una época dura, pero también aprendiste mucho. Puede que tu maestro esté vivo en alguna parte, o puede que lo matara la Guardia de Hierro.",
  "[event] studied with a master items": "Obtienes pergamino, tinta y pluma.",
  "[event] protected the forest": "protectora del bosque",
  "[event] protected the forest story":
    "Tomaste la responsabilidad de proteger el bosque contra el mal y la violencia de los extraños. Viviste al abrigo de los imponentes árboles y te hiciste una con el bosque.",
  "[event] protected the forest items":
    "Obtienes un arco corto y un D12 como dado de recursos para flechas",
  "[event] lived as a hermit": "ermitaña",
  "[event] lived as a hermit story":
    "Buscaste la belleza de la naturaleza, lejos de los conflictos violentos de las Tierras Prohibidas. Cuando finalmente volviste, lo hiciste con una fuerza nueva.",
  "[event] lived as a hermit items":
    "Obtienes una tienda de campaña pequeña y utensilios de cocina.",
  "[event] captured": "capturada",
  "[event] captured story":
    "Fuiste capturada de forma vergonzosa por enemigos. Te arrojaron a una mazmorra para olvidarse de ti y que murieras. Cuando apareció la oportunidad de escapar, la aprovechaste.",
  "[event] captured items": "Obtienes una armadura de cuero tachonado.",
  "[event] bloody battle": "batalla sangrienta",
  "[event] bloody battle story":
    "Tomaste parte en una batalla terrible y te hirieron gravemente. Cuando amaneció, tu bando había sido derrotado hasta el último hombre y el enemigo había dejado tu cuerpo a los cuervos.",
  "[event] bloody battle items": "Obtienes una espada bastarda.",
  "[event] a long march": "una larga marcha",
  "[event] a long march story":
    "Te afiliaste como soldado y marchaste por las Tierras Prohibidas. Casi no recuerdas por qué luchabas o quién era vuestro enemigo, pero sí recuerdas que las fuerzas de la naturaleza se llevaron a tus compañeros uno a uno.",
  "[event] a long march items":
    "Obtienes una tienda de campaña pequeña, una manta y un caldero.",
  "[event] on horseback": "en la grupa de un caballo",
  "[event] on horseback story":
    "Aprendiste a montar y te hiciste caballera con un señor. Luchaste en escaramuzas, formaste parte de patrullas y protegiste las tierras de tu señor.",
  "[event] on horseback items":
    "Obtienes una montura de tu elección (excepto caballo de guerra).",
  "[event] scout": "exploradora",
  "[event] scout story":
    "Te uniste a una compañía de soldados y te pusieron a la vanguardia como exploradora. Tu misión era descubrir al enemigo antes de que os descubrieran, y eras buena en eso. ¿Le pasó algo a tu pelotón, o que justo en esa ocasión no estuviste atenta?",
  "[event] scout items":
    "Obtienes un arco corto y un D12 como dado de recurso para flechas",
  "[event] commander": "comandante",
  "[event] commander story":
    "Estabas al mando de un pelotón de soldados. Tus compañeros de armas te respetaban y juraron morir por ti. Pero algo pasó, puede que te traicionaran o que os tendieron una emboscada. Tus compañeros cayeron o huyeron, pero tú sobreviviste",
  "[event] commander items":
    "Obtienes una cota de malla y una celada descubierta.",
  "[event] hunted a beast": "bestia cazada",
  "[event] hunted a beast story":
    "La caza lo era todo. Perseguiste a la bestia por tierra, agua y montañas. Algunas veces tú eras la presa.",
  "[event] hunted a beast items":
    "Obtienes una lanza corta y una trampa de osos.",
  "[event] lived off the forest": "criada en el bosque",
  "[event] lived off the forest story":
    "Viviste en el bosque y este te dio sustento. De día cazabas, y de noche dormías bajo las estrellas. Era una vida sencilla, pero te gustaba, y un día se terminó.",
  "[event] lived off the forest items": "Obtienes una jabalina y una daga.",
  "[event] tamed a mount": "montura domesticada",
  "[event] tamed a mount story":
    "Atrapaste a un caballo salvaje (u otro animal que encaje). No fue fácil, pero por fin lo domesticaste y con el tiempo os hicisteis inseparables.",
  "[event] tamed a mount items":
    "Obtienes una montura de tu elección. No puede ser un caballo de guerra.",
  "[event] won an archery competition": "ganadora de una competición de arco",
  "[event] won an archery competition story":
    "Te diste cuenta pronto de que tenías un talento natural con el arco, y te dispusiste a ser incluso mejor. Participaste en una competición cuando visitaste un pueblo grande o un castillo, y ganaste.",
  "[event] won an archery competition items":
    "Obtienes un arco largo y un D12 como dado de recursos para flechas.",
  "[event] guide": "guía",
  "[event] guide story":
    "YPrestaste tu talento como guía a aquellos que pagaban bien. Guiaste caravanas de mercaderes, tropas de soldados y aventureros curiosos. Aprendiste el arte de cruzar las Tierras Prohibidas y a vivir de ellas.",
  "[event] guide items":
    "Obtienes una tienda de campaña pequeña, un caldero y una manta",
  "[event] hunted for a lord": "cazadora de un señor",
  "[event] hunted for a lord story":
    "Un noble se dio cuenta de tus habilidades y te contrató como guardabosques. Viviste una buena vida: la mitad del tiempo entre las comodidades del castillo y el resto cazando en el bosque.",
  "[event] hunted for a lord items":
    "Obtienes una capa de buena factura y pieles de dormir.",
  "[event] wrote a popular ballad": "autora de una balada popular",
  "[event] wrote a popular ballad story":
    "Una balada o canción que escribiste se hizo inmensamente popular en los alrededores. Hoy sigues escuchando a los juglares locales tocando tu pieza.",
  "[event] wrote a popular ballad items": "Obtienes tinta, pluma y pergamino.",
  "[event] worked as a minstrel": "trovadora",
  "[event] worked as a minstrel story":
    "Usaste tus talentos artísticos para vivir de trovadora. Puede que fueras itinerante o que trabajaras en un lugar fijo, como una taberna o un castillo.",
  "[event] worked as a minstrel items": "Obtienes un laúd.",
  "[event] inspirational journey": "viaje inspirador",
  "[event] inspirational journey story":
    "Viajaste por las Tierras Prohibidas para aprender de las razas, culturas y leyendas. En tu viaje te encontraste muchas aventuras y reuniste suficiente material para un libro de baladas.",
  "[event] inspirational journey items":
    "Obtienes una tienda de campaña pequeña, una manta y un caldero.",
  "[event] sang for a lord": "cantante de un señor",
  "[event] sang for a lord story":
    "Tu talento llamó la atención de un señor local, que te ofreció un puesto para castillo se llenó de vino, comida deliciosa y el tintineo de la plata, pero también de intrigas insidiosas, asesinatos y celos.",
  "[event] sang for a lord items": "Obtienes ropa buena y una daga.",
  "[event] enlisted [minstrel]": "reclutada",
  "[event] enlisted [minstrel] story":
    "La vida dio un giro repentino y te reclutaron en una compañía de soldados. La vida fue dura, pero hiciste amigos leales y aprendiste a vivir de la tierra.",
  "[event] enlisted [minstrel] items":
    "Obtienes una armadura de cuero y un casquete.",
  "[event] dueled a rival": "duelo",
  "[event] dueled a rival story":
    "Un rival te vio como una piedra en su zapato. Durante un tiempo vuestros destinos se cruzaron de las formas más problemáticas: amor, dinero y trabajo. Al final fue demasiado. Te retó a un duelo y te hirió. Puede que tu rival aún esté por alguna parte.",
  "[event] dueled a rival items": "Obtienes una espada corta.",
  "[event] caravan": "caravana",
  "[event] caravan story":
    "Formaste parte de una dura caravana comercial que viajó por las Tierras Prohibidas. La caravana se encontró con muchos peligros y no todos llegaron a su destino. ¿Puede que dejarais a alguien atrás?",
  "[event] caravan items": "Obtienes un burro",
  "[event] local business": "negocio local",
  "[event] local business story":
    "Viste una oportunidad financiera y la aprovechaste al máximo. Puede que importaras fruta al castillo de un señor o que vendieras telas en Alisea. Los negocios fueron viento en popa, al menos por un tiempo.",
  "[event] local business items": "Obtienes una carreta",
  "[event] cheated": "engañada",
  "[event] cheated story":
    "Un compañero en el que confiabas te engañó y te dejó sin nada. Aprendiste una lección importante de la forma más dura, y pasaste mucho tiempo reconstruyendo tu vida desde cero. ¿Puede que tu antiguo compañero siga vivo?",
  "[event] cheated items":
    "Obtienes una tienda de campaña pequeña, una manta y un caldero.",
  "[event] side mission": "de aventuras",
  "[event] side mission story":
    "Te uniste a un grupo de aventureros y fuiste a vivir grandes aventuras en las Tierras Prohibidas. Os enfrentasteis a muchos peligros y muchos perecieron durante el viaje, pero aprendiste mucho sobre ti misma.",
  "[event] side mission items":
    "Obtienes una cota de malla y un escudo pequeño.",
  "[event] smuggler": "contrabandista",
  "[event] smuggler story":
    "Creaste un lucrativo negocio de contrabando entre asentamientos de las Tierras Prohibidas. Puede que traficaras con armas o drogas prohibidas. Aprendiste lo importante de ser discreta y cómo evitar llamar la atención.",
  "[event] smuggler items": "Obtienes un catalejo.",
  "[event] ran a shop": "dueña de una tienda",
  "[event] ran a shop story":
    "Elegiste asentarte en un pueblo y llevaste una tienda durante un tiempo. Pudiste vivir de ello, pero no de forma holgada. Al final te diste cuenta de que la vida tranquila no era para ti.",
  "[event] ran a shop items": "Obtienes una balanza, tinta, pluma y pergamino.",
  "[event] enlisted [rider]": "reclutada",
  "[event] enlisted [rider] story":
    "Te uniste a una compañía de soldados. Puede que te unieras por tu propia voluntad o que te obligaran. Con tus compañeros de armas os enfrentasteis a muchas adversidades: batallas, largas marchas y tiempo inclemente. Y al final, pasó algo y dejaste la compañía.",
  "[event] enlisted [rider] items":
    "Obtienes una armadura de cuero tachonado yun escudo grande.",
  "[event] competed": "competición",
  "[event] competed story":
    "Te ganaste la vida como jinete de competiciones y viajaste de pueblo en pueblo y de castillo a castillo para competir contra otros jinetes. Vivías bien mientras ganabas, pero cuando empezaste a perder fue el momento de seguir adelante.",
  "[event] competed items": "Obtienes una capa.",
  "[event] traveled the land": "viajera",
  "[event] traveled the land story":
    "Tu deseo de aventura te absorbió y te marchaste para cabalgar a lo largo y ancho de las Tierras Prohibidas. Cabalgaste por páramos desolados y fuertes tormentas y te enfrentaste a muchos peligros.",
  "[event] traveled the land items":
    "Obtienes una tienda de campaña pequeña, una manta y un caldero.",
  "[event] caravan guard": "guardia de caravana",
  "[event] caravan guard story":
    "Conseguiste empleo como guardia de una caravana y escoltaste a los comerciantes a través de los bosques oscuros y las altas montañas.",
  "[event] caravan guard items": "Obtienes una lanza corta.",
  "[event] cattle herder": "pastora de ganado",
  "[event] cattle herder story":
    "Arreaste al ganado hacia los pastos más verdes de las Tierras Prohibidas. De día cuidabas del rebaño en la grupa de tu caballo, de noche dormías bajo las estrellas.",
  "[event] cattle herder items": "Obtienes un farol y un odre de agua.",
  "[event] steppe rider": "jinete de las estepas",
  "[event] steppe rider story":
    "Cabalgaste por las interminables estepas de las Tierras Prohibidas y viviste solo de lo que cazabas y de vender la carne y las pieles de los animales.",
  "[event] steppe rider items":
    "Obtienes un arco corto y un D12 como dado de recursos para flechas.",
  "[event] stole from the wrong person": "robaste a la persona equivocada",
  "[event] stole from the wrong person story":
    "Era el crimen perfecto, al menos durante un tiempo. Robaste algo valioso a la persona equivocada en el momento equivocado. Te pillaron y tuviste que correr por tu vida.",
  "[event] stole from the wrong person items": "Obtienes unas ganzúas.",
  "[event] imprisoned": "presa",
  "[event] imprisoned story":
    "Culpable o no, te pillaron y te mandaron a la cárcel. Pasaste mucho tiempo en la oscuridad, pero nunca te rendiste. Al final surgió una oportunidad para escapar y la aprovechaste.",
  "[event] imprisoned items": "Obtienes una daga.",
  "[event] successful heist": "golpe exitoso",
  "[event] successful heist story":
    "Planeaste un golpe y salió bien. Puede que fuera a un comerciante rico, puede que a un señor local",
  "[event] successful heist items":
    "Tira en la tabla de Hallazgos valiosos en guarida; vuelve a tirar si el resultado es menor de 33.",
  "[event] joined a guild of thieves": "miembro de un gremio de ladrones",
  "[event] joined a guild of thieves story":
    "Buscaste una asociación de ladrones e ingresaste en su comunidad. Juntos planeasteis golpes y llevasteis a cabo misiones peligrosas",
  "[event] joined a guild of thieves items": "Obtienes una armadura de cuero.",
  "[event] enlisted [rogue]": "alistada",
  "[event] enlisted [rogue] story":
    "Te encontraste, probablemente en contra de tu voluntad, alistada en una compañía de soldados. Trabajaste como exploradora a mucha distancia por delante de los demás. Pasaste muchas horas llenas de miseria y peligro, pero también de amistad",
  "[event] enlisted [rogue] items":
    "Recibes un arco corto y un D12 como dado de recursos para flechas.",
  "[event] band of robbers": "banda de ladrones",
  "[event] band of robbers story":
    "Desapareciste en el bosque y te uniste a una banda de ladrones que vivían de los mercaderes y caravanas que se tropezaban en su camino. La vida en el bosque era llevadera y buena, al menos mientras duró, pero al final fuisteis expulsados del bosque.",
  "[event] band of robbers items": "Obtienes una espada corta.",
  "[event] failed experiment": "experimento fallido",
  "[event] failed experiment story":
    "Realizaste un experimento mágico que fracasó estrepitosamente. Puede que alguien saliera herido o que te expulsaran de tu hogar.",
  "[event] failed experiment items":
    "Obtienes 1D6 dosis de veneno alucinógeno.",
  "[event] secluded studies": "recluida en los estudios",
  "[event] secluded studies story":
    "Saliste en busca de un lugar apartado para sumergirte en el estudio de la magia. Viviste de lo que te daba la naturaleza y tus únicos compañeros fueron los libros y los pergaminos.",
  "[event] secluded studies items":
    "Obtienes una tienda de campaña pequeña y un caldero.",
  "[event] found strange artifact": "aterfacto extraño encontrado",
  "[event] found strange artifact story":
    "Te encontraste un extraño artefacto en tus viajes por las Tierras Prohibidas. Te obsesionaste con su historia y creíste que podía tener poderes mágicos.",
  "[event] found strange artifact items":
    "Tira en la tabla de «Hallazgos valiosos en guarida»; vuelve a tirar si el resultado es menor de 32.",
  "[event] court magician": "maga de la corte",
  "[event] court magician story":
    "Un señor local te contrató y le serviste como su consejera arcana. Puede que él tuviera unos objetivos secretos, puede que entraras en conflicto con otros consejeros que estaban por encima de ti. De todas formas, aprendiste mucho de los poderosos.",
  "[event] court magician items":
    "Obtienes una botella de vino caro y una capa.",
  "[event] entertainer": "artista",
  "[event] entertainer story":
    "Trabajaste como artista ambulante y usaste tu conocimiento de la magia para realizar ilusiones y trucos mágicos. Puede que trabajaras en una taberna de mala muerte o que te contrataran en la corte de un noble.",
  "[event] entertainer items": "Obtienes una bola de cristal y ropa buena.",
  "[event] enlisted [sorcerer]": "reclutada",
  "[event] enlisted [sorcerer] story":
    "Te reclutaron en una compañía de soldados. Pasaste mucho tiempo en el terreno,donde ayudaste con tus conocimientos mágicos. Viste morir a tus compañeros, pero te las arreglaste para salir con solo una o dos cicatrices.",
  "[event] enlisted [sorcerer] items":
    "Obtienes una armadura de cuero tachonado y una celada descubierta.",
}

const lowercase = {
  "action": "acción",
  "animal companion": "animal compañero",
  "armor": "armadura",
  "armslength": "al alcance del brazo",
  "artifact": "artefacto",
  "attribute": "característica",
  "axe": "hacha",
  "backpack": "mochila",
  "blunt": "contundente",
  "bonus": "bono",
  "bow": "arco",
  "category": "categoria",
  "character": "personaje",
  "childhood": "infancia",
  "combat": "combate",
  "combat-action-fast": "acción rápida",
  "combat-action-fast-performed": "acción rápida realizada",
  "combat-action-slow": "acción lenta",
  "combat-action-slow-performed": "acción lenta realizada",
  "confirm": "confirmar",
  "create from template": "crear desde plantilla",
  "crossbow": "ballesta",
  "description": "descripción",
  "dmg": "daño",
  "edged": "cortante",
  "epic": "épico",
  "female": "mujer",
  "fighting monster": "monstruo luchando",
  "formative event": "evento formativo",
  "helmets": "cascos",
  "hook": "gancho",
  "humanoid": "humanoide",
  "initiative": "iniciativa",
  "item": "objeto",
  "knife": "cuchillo",
  "legendary": "legendario",
  "long": "larga",
  "male": "hombre",
  "melee weapons": "armas de cuerpo a cuerpo",
  "mighty": "poderoso",
  "monster": "monstruo",
  "movement rate": "valor movimiento",
  "name": "nombre",
  "near": "cerca",
  "next round": "próximo turno",
  "no character": "sin personaje",
  "no suitable weapon equipped": "sin arma adecuada equipada",
  "none": "ninguno",
  "opponent": "oponente",
  "options": "opciones",
  "or more": "o más",
  "other": "otro",
  "parry": "parada",
  "parrying": "parada",
  "pointed": "punzante",
  "polearm": "lanza",
  "probability": "probabilidad",
  "properties": "características",
  "ranged weapons": "armas a distancia",
  "select character": "seleccionar personaje",
  "sex": "sexo",
  "shields": "escudos",
  "short": "corta",
  "skill": "habilidad",
  "slow_loading": "recarga lenta",
  "sword": "espada",
  "talents": "talentos",
  "thrown": "arrojado",
  "unarmed": "desarmado",
}

export default {
  ...age,
  ...attributes,
  ...kin,
  ...professions,
  ...skills,
  ...talents,
  ...GearDescriptions,
  ...weapons,
  ...combatActionsFast,
  ...combatActionsSlow,
  ...keyedTranslations,
  ...mixedCase,
  ...lowercase,
  ...multiLine,
}
