
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

@Component
export class FLNumberInput extends Vue {
  @Prop({ default: "" }) width!: string
  @Prop({ default: "" }) fontSize!: string
  @Prop({ default: "" }) placeholder!: string
  @Prop({ default: "" }) max!: string
  @Prop({ default: "" }) min!: string
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: () => () => {} }) enterCb!: Function
  @Prop({ default: () => () => {} }) ctrlEnterCb!: Function
  @Prop({ default: null }) value!: number | null
  @Prop({ default: "" }) label!: string
  @Prop({ default: false }) inGrid!: boolean

  @Prop({ default: true }) canIncrement!: boolean
  @Prop({ default: true }) canDecrement!: boolean
  @Prop({ default: false }) fieldDisabled!: boolean

  get cssProps() {
    const placeholderWidth = this.placeholder
      ? `${this.placeholder.length}ch`
      : ""
    const maxWidth =
      this.max || this.min
        ? `${Math.max(
            String(this.max).length ?? 0,
            String(this.min).length ?? 0
          )}ch`
        : ""
    return {
      "--width": this.width || placeholderWidth || maxWidth || "2rem",
      "--font-size": this.fontSize || "2rem",
    }
  }

  inputEvent($event: any) {
    this.$emit("input", Number($event.target.value))
  }

  increment() {
    if (this.max && Number(this.value) >= Number(this.max)) {
      return
    }
    this.$emit("input", Math.max(Number(this.value) + 1, Number(this.min)))
  }

  decrement() {
    if (this.min && Number(this.value) <= Number(this.min)) {
      return
    }
    this.$emit("input", Number(this.value) - 1)
  }

  get incrementDisabled() {
    if (this.disabled || !this.canIncrement) return true
    if (!this.max || !this.value) return false
    return Number(this.value) >= Number(this.max)
  }

  get decrementDisabled() {
    if (this.disabled || !this.canDecrement) return true
    if (!this.min) return false
    return Number(this.value) <= Number(this.min)
  }
}

export default FLNumberInput
