
/* eslint-disable no-console */
import Vue from "vue"

import Notify from "@/components/base/Notify.vue"
import NavBar from "@/components/base/NavBar.vue"
import DiceModal, { defaultDice } from "@/components/dice/DiceModal.vue"
import { Updater } from "@/components/base/Updater.vue"
import { SideNav } from "src/components/nav/SideNav.vue"
import { LOCALE_KEY } from "@/i18n"
import { getDefaultLocale } from "src/locales/localeUtil"
import { NavContent } from "src/components/nav/NavContent.vue"
import { APP_VERSION } from "src/util/const"

const DEFAULT_DICE_MODAL_CONF = {
  title: "",
  dice: defaultDice(),
  charData: null,
  disablePush: false,
}

export default Vue.extend({
  components: {
    DiceModal,
    NavBar,
    NavContent,
    Notify,
    SideNav,
    Updater,
  },
  data() {
    return {
      buildVersion: APP_VERSION,
      showNav: false,
      showDiceModal: false,
      diceModalConfig: DEFAULT_DICE_MODAL_CONF,
    }
  },
  methods: {
    onOpenDiceModal(args: any) {
      // FIXME: Abstract away the keys for settings this up into separate module
      // FIXME: Enable handling of willpower
      const { title, dice, disablePush, charData = null } = args
      this.diceModalConfig = {
        ...this.diceModalConfig,
        title,
        dice,
        disablePush,
        charData,
      }
      this.showDiceModal = true
    },
    onCloseDiceModal() {
      this.diceModalConfig = DEFAULT_DICE_MODAL_CONF
      this.showDiceModal = false
    },
    addEventListener(name: string) {
      this.$root.$on(name, this.onOpenDiceModal)
    },
    removeEventListener(name: string) {
      this.$root.$off(name)
    },
    useLocale() {
      if (localStorage.getItem(LOCALE_KEY)) {
        return // locale has been set by user
      }
      this.$i18n.locale = getDefaultLocale(navigator.languages)
    },
  },
  created() {
    this.addEventListener("open-dice-modal")
    this.useLocale()
  },
  mounted() {
    const d = document.getElementById("splash-screen")
    d?.classList.toggle("hidden", true)
  },
  beforeDestroy() {
    this.removeEventListener("open-dice-modal")
  },
})
