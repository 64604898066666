
import Vue from "vue"

export default Vue.extend({
  name: "Fallback",
  props: {
    error: Error,
  },
  methods: {
    downloadBackup: () => {
      alert("NOT IMPLEMENTED")
    },
  },
  created() {
    throw this.error
  },
})
