
import { Component, Prop, Vue } from "vue-property-decorator"
import ExpandableSection from "@/components/ExpandableSection.vue"
import FLButton from "@/components/base/FLButton.vue"
import Modal from "@/components/Modal.vue"
import { Notification, notify } from "@/util/notifications"
import DiceRoller from "@/components/dice/DiceRoller.vue"
import { CharData } from "@/data/character/characterData"
import { SkillObj } from "@/skills"
import { Option, Optgroup, FLSelect } from "@/components/base/FLSelect.vue"
import { ItemWeapon } from "src/data/items/itemTypes"

const rollTypes = [
  { type: "generic", name: "Generic" },
  { type: "attribute", name: "Attribute" },
  { type: "skill", name: "Skill" },
  { type: "combat", name: "Combat" },
]

const rollForAttribute = [
  { type: "strength" },
  { type: "agility" },
  { type: "intelligence" },
  { type: "charisma" },
]

const rollForCombat = [
  { type: "melee" },
  { type: "ranged" },
  { type: "dodge" },
  { type: "parry" },
]

const skill = [
  { type: "skill1" },
  { type: "skill2" },
  { type: "skill3" },
  { type: "skill4" },
]

/**
 * TODO:
 * Enable rolling dice for
 * - attributes
 * - skills
 * - combat (melee, ranged, armor, ducky, parry)
 *
 * Go through talents for talents that add bonuses to appropriate rolls
 */
@Component({
  components: {
    DiceRoller,
    ExpandableSection,
    FLButton,
    Modal,
    FLSelect,
  },
})
export default class SkillRoller extends Vue {
  @Prop({ required: false }) charData!: CharData

  rollTypes = rollTypes
  rollForAttribute = rollForAttribute
  rollForCombat = rollForCombat
  skill = skill

  selectedRollType = rollTypes[0].type
  selectedAttribute = rollForAttribute[0].type
  selectedCombatAction = rollForCombat[0].type
  selectedSkill = null
}
