
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import SvgIcon from "@/components/SvgIcon.vue"

@Component({
  components: {
    SvgIcon,
  },
})
export class FLButton extends Vue {
  @Prop({ default: "button" }) formButtonType!: string
  @Prop({ default: "main" }) type!: "" | "danger" | "cancel" | "ghost" | "main"
  @Prop({ default: "white" }) color!: "main" | "danger" | "white" | "inherit"
  @Prop({ default: "" }) href!: string
}

export default FLButton
