
// TODO: Split this up into smaller components
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import SvgIcon from "@/components/SvgIcon.vue"
import ExpandableSection from "@/components/ExpandableSection.vue"
import DiceInput from "@/components/dice/DiceInput.vue"
import DiceResult from "@/components/dice/DiceResult.vue"
import FLButton from "@/components/base/FLButton.vue"
import { getRandomIntInclusive } from "@/dice/diceUtil"
import { IDiceConfig } from "@/dice/diceTypes"
import { FLNumberInput } from "@/components/base/FLNumberInput.vue"

import { DiceProbability } from "./DiceProbability.vue"
import { capitalize } from "@/util/util"

enum DiceType {
  White,
  Red,
  Black,
  Green,
  Blue,
  Orange,
}

function successMap(val: number) {
  if (val < 6) return 0
  if (val >= 6 && val <= 7) {
    return 1
  } else if (val >= 8 && val <= 9) {
    return 2
  } else if (val >= 10 && val <= 11) {
    return 3
  }
  return 4
}

function diceResult(sides = 6) {
  return getRandomIntInclusive(1, sides)
}

function diceResults(nbrDice: number | null, sides = 6): number[] {
  let result: number[] = []
  if (!nbrDice) return result
  for (let i = 0; i < nbrDice; i++) {
    result.push(diceResult(sides))
  }
  return result
}

function sidesFor(diceType: DiceType): number {
  const map = {
    [DiceType.Green]: 8,
    [DiceType.Blue]: 10,
    [DiceType.Orange]: 12,
  }
  return (map as any)[diceType] || 6
}

function getNbrDice(
  white: number | null,
  red: number | null,
  black: number | null,
  green: number | null,
  blue: number | null,
  orange: number | null
): (number | null)[] {
  return [white, red, black, green, blue, orange]
}

function diceArrayToConf(dice: (number | null)[]): IDiceConfig {
  return {
    white: dice[0],
    red: dice[1],
    black: dice[2],
    green: dice[3],
    blue: dice[4],
    orange: dice[5],
  }
}

@Component({
  components: {
    DiceInput,
    DiceProbability,
    DiceResult,
    ExpandableSection,
    FLButton,
    FLNumberInput,
    SvgIcon,
  },
})
export class DiceRoller extends Vue {
  // FIXME: Take an IDiceConfig instead of individual values
  @Prop({ default: null }) white!: number
  @Prop({ default: null }) red!: number
  @Prop({ default: null }) black!: number
  @Prop({ default: null }) green!: number
  @Prop({ default: null }) blue!: number
  @Prop({ default: null }) orange!: number
  @Prop({ default: true }) showReset!: boolean
  @Prop({ default: undefined }) willpower!: number | undefined | null
  @Prop({ default: false }) isDwarf!: boolean
  @Prop({ default: false }) infinityPush!: boolean
  @Prop({ default: false }) showButtonBar!: boolean // remove?

  @Watch("black", { immediate: true })
  setBlack() {
    this.nbrDice = [
      this.white,
      this.red,
      this.black,
      this.green,
      this.blue,
      this.orange,
    ]
  }

  DiceType = DiceType

  nbrDice: (number | null)[] = getNbrDice(
    this.white,
    this.red,
    this.black,
    this.green,
    this.blue,
    this.orange
  )

  rollResult: number[][] = [
    /* Address by enum number */
  ]
  rollResultLog: number[][][] = []

  accumulator = (sum: number, value: number | null) => sum + Number(value)

  created() {
    this.$emit("rollCb", this.rollDice)
    this.$emit("pushCb", this.pushRoll)
    this.$emit("resetCb", this.resetDice)
    this.$emit("pushDisabled", this.pushDisabled)
  }

  get showWillpower() {
    return this.willpower !== null && Number(this.willpower) >= 2
  }

  get conf(): IDiceConfig {
    return diceArrayToConf(this.nbrDice)
  }

  get totals() {
    const rolls = this.rollResultLog[0]

    if (!rolls) return []
    const t = rolls.map((rolls, diceType) => {
      const success = rolls
        .map((value) => successMap(value))
        .filter((val) => val > 0)
        .reduce((sum: number, value: number) => sum + value, 0)
      const fails = [DiceType.White, DiceType.Black].includes(diceType)
        ? rolls.filter((val) => val <= 1).length
        : 0
      return { success, fails }
    })
    return t
  }

  get totalSuccess() {
    return this.totals.map((item) => item.success).reduce(this.accumulator, 0)
  }

  get totalWhiteSkulls() {
    return this.totals.length > 0 && this.totals[0] ? this.totals[0].fails : ""
  }

  get totalBlackSkulls() {
    return this.totals.length > 0 && this.totals[2] ? this.totals[2].fails : ""
  }

  get pushed() {
    return this.rollResultLog.length >= 2
  }

  get rolled() {
    return this.rollResultLog.length >= 1
  }

  get pushDisabled(): boolean {
    if (this.rolled && !this.pushed) {
      return false // can always push at least once after a roll
    }
    if (this.rolled && this.infinityPush) {
      return false // for dice roller not coupled with character
    }
    if (this.rolled && this.willpower === undefined) {
      return false // undefined WP will allow indefinite pushes after a roll
    }
    if (this.rolled && this.isDwarf && this.willpower) {
      return false // a dwarf with willpower kan keep pushing
    }
    return true
  }

  get rollDisabled() {
    return this.nbrDice.reduce(this.accumulator, 0) < 1
  }

  pushRoll() {
    if (this.pushed && this.isDwarf && this.willpower) {
      this.handleWillpower(this.willpower - 1)
    }

    const newRolls = this.rollResult.map((results, index) => {
      return results.map((result) => {
        if (
          (result === 1 && [DiceType.White, DiceType.Black].includes(index)) ||
          result >= 6
        ) {
          return result // Don't reroll 6's; or 1's for White and Black dice
        }
        return diceResult()
      })
    })
    this.rollResult = newRolls
    this.rollResultLog.unshift(this.rollResult)
    const refRollResult = this.$refs.rollResults as any
    this.$nextTick(() => {
      refRollResult.scrollTop = refRollResult.scrollHeight
    })
    this.$emit("pushDisabled", this.pushDisabled)
  }

  rollDice() {
    this.rollResultLog = []
    this.rollResult = this.nbrDice.map((nbrDice, diceType) => {
      const sides = sidesFor(diceType)
      return diceResults(nbrDice, sidesFor(diceType))
    })
    this.rollResultLog.push(this.rollResult)
    this.$emit("pushDisabled", this.pushDisabled)
  }

  resetDice() {
    this.nbrDice = getNbrDice(
      this.white,
      this.red,
      this.black,
      this.green,
      this.blue,
      this.orange
    )
    this.rollResult = []
    this.rollResultLog = []
    this.$emit("pushDisabled", this.pushDisabled)
  }

  handleWillpower(value: any) {
    this.$emit("wp-update", value)
  }

  get basicDice() {
    return [
      { color: "white", type: DiceType.White },
      { color: "red", type: DiceType.Red },
      { color: "black", type: DiceType.Black },
    ]
  }

  get artifactDice() {
    return [
      { color: "green", type: DiceType.Green },
      { color: "blue", type: DiceType.Blue },
      { color: "orange", type: DiceType.Orange },
    ]
  }

  get probabilityStr() {
    return capitalize(this.$t("probability") as string)
  }
}

export default DiceRoller
