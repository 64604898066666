
import { Component, Vue } from "vue-property-decorator"
import { MULTIPLAY_KEY } from "src/util/const"
import LocaleChanger from "@/components/LocaleChanger.vue"
import { APP_VERSION } from "src/util/const"

@Component({
  components: {
    LocaleChanger,
  },
})
export class NavContent extends Vue {
  buildVersion = APP_VERSION
  get multiplayEnabled() {
    return !!localStorage.getItem(MULTIPLAY_KEY)
  }
}

export default NavContent
