// This shit needs to be seriously refactored, the relationship between keys
// types and talents in this module is too damn confusing
import { CLASS, KIN, TALENT } from "@/keys"
import {
  KinName,
  Profession,
  TalentAll,
  TalentGeneral,
  TalentKin,
  TalentProfession,
} from "@/types"

export interface Talent {
  id: TalentAll
  name?: string
  translationKey: string // TODO should be removed, and translations updated
  description_id?: string[]
}

// TODO: Remove
export const transformToTranslationKey = (s: string) =>
  s.replace(/ /g, "_").toUpperCase()

export const KIN_TALENTS = {
  //TODO: remove
  [KIN.DWARF]: {
    id: "True Grit",
    translationKey: transformToTranslationKey("True Grit"),
    description_id: [""],
  },
  [KIN.ELF]: {
    id: "Inner Peace",
    tra: transformToTranslationKey("Inner Peace"),
    description_id: [""],
  },
  [KIN.GOBLIN]: {
    id: "Sneaky",
    translationKey: transformToTranslationKey("Sneaky"),
    description_id: [""],
  },
  [KIN.HALFELF]: {
    id: "Psychic Power",
    tra: transformToTranslationKey("Psychich Power"),
    description_id: [""],
  },
  [KIN.HALFLING]: {
    id: "Hard to Catch",
    translationKey: transformToTranslationKey("Hard to Catch"),
    description_id: [""],
  },
  [KIN.HUMAN]: {
    id: "Adaptive",
    translationKey: transformToTranslationKey("Adaptive"),
    description_id: [""],
  },
  [KIN.ORC]: {
    id: "Unbreakable",
    translationKey: transformToTranslationKey("Unbreakable"),
    description_id: [""],
  },
  [KIN.WOLFKIN]: {
    id: "Hunting Instincts",
    translationKey: transformToTranslationKey("Hunting Instincts"),
    description_id: [""],
  },
}

type KinTalentMap = { [key in KinName]: TalentKin }
export const KIN_TALENTS2: KinTalentMap = {
  human: "Adaptive",
  halfling: "Hard to Catch",
  wolfkin: "Hunting Instincts",
  elf: "Inner Peace",
  halfelf: "Psychic Power",
  goblin: "Sneaky",
  dwarf: "True Grit",
  orc: "Unbreakable",
}

type ProfessionTalentMap = { [key in Profession]: TalentProfession[] }
export const PROFESSION_TALENTS: ProfessionTalentMap = {
  champion: ["Path of Protection", "Path of Fate", "Path of the Holy Vow"],
  druid: [
    "Path of Healing",
    "Path of Shifting Shapes",
    "Path of Sight",
    "Ice Affinity",
  ],
  fighter: ["Path of the Blade", "Path of the Enemy", "Path of the Shield"],
  hunter: ["Path of the Arrow", "Path of the Beast", "Path of the Forest"],
  minstrel: ["Path of the Hymn", "Path of the Song", "Path of the Warcry"],
  peddler: ["Path of Gold", "Path of Lies", "Path of Many Things"],
  rider: ["Path of the Companion", "Path of the Knight", "Path of the Plains"],
  rogue: ["Path of the Face", "Path of the Killer", "Path of Poison"],
  sorcerer: [
    "Path of Blood",
    "Path of Death",
    "Path of Signs",
    "Path of Stone",
    // Expansions:
    "Dream Magic",
    "Elemental Magic",
    "Magma Song",
    "Magnetism",
    "Mentalism",
  ],
}

export function getTalentsForProfession(
  profession: Profession | null,
  options?: { specialTalents?: boolean }
): TalentProfession[] {
  if (!profession) return []
  const professionTalents = PROFESSION_TALENTS[profession]
  if (profession === "sorcerer") {
    const sorcererSpecialFilter = [
      "Dream Magic",
      "Magma Song",
      "Magnetism",
      "Mentalism",
    ]
    if (!!options?.specialTalents) {
      return professionTalents.filter((t) => sorcererSpecialFilter.includes(t))
    }
    return professionTalents.filter((t) => !sorcererSpecialFilter.includes(t))
  }

  if (!!options?.specialTalents) {
    return []
  }
  return professionTalents
}

// Metadata for General Talents
export type TalentRank = 0 | 1 | 2 | 3

export const GENERAL_TALENTS: TalentGeneral[] = [
  // An array of Talent IDs
  "ambidextrous",
  "axe fighter",
  "berserker",
  "bowyer",
  "brawler",
  "builder",
  "chef",
  "cold blooded",
  "defender",
  "dragonslayer",
  "executioner",
  "fast footwork",
  "fast shooter",
  "fearless",
  "firm grip",
  "fisher",
  "hammer fighter",
  "herbalist",
  "horseback fighter",
  "incorruptible",
  "knife fighter",
  "lightning fast",
  "lockpicker",
  "lucky",
  "master of the hunt",
  "melee charge",
  "pack rat",
  "pain resistant",
  "pathfinder",
  "poisoner",
  "quartermaster",
  "quickdraw",
  "sailor",
  "sharp tongue",
  "sharpshooter",
  "shield fighter",
  "sixth sense",
  "smith",
  "spear fighter",
  "steady feet",
  "sword fighter",
  "tailor",
  "tanner",
  "threatening",
  "throwing arm",
  "wanderer",

  "harpooner",
  "mountaineer",
  "warm blooded",
]
export const GENERAL_TALENTS2 = GENERAL_TALENTS
